import React from "react";
// import configData from "../config.json";
import "../component_css/TeamAnalyzer.css"
import "../component_css/PairwiseUsage.css"

import { parseSeasonFromPath, checkEmptyObject, capitalizeFirstLetter,
  computeWeaknessModifier, getPairwiseUsageInfo, getPokemonIDDetailsInfo,
  getDraftOrderRosterInfo, generateTypes } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();
  

class PairwiseUsage extends React.Component {
  constructor() {
    super()
    this.state = {
        attackPlayer: null,
        colorByUsage: true,
        gyThreshold: 0.6,
        yrThreshold: 0.4
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.pokemonData = {};
    this.usageData = {};
    this.rosterOrder = {};

    this.setAttackPlayer = this.setAttackPlayer.bind(this);
    this.generatePlayerDropdown = this.generatePlayerDropdown.bind(this);
    this.generateColorToggleButton = this.generateColorToggleButton.bind(this);
    this.generateTeamAnalysisTable = this.generateTeamAnalysisTable.bind(this);
    this.seasonConfig = configData.LEAGUE_CONFIG[this.state.seasonNum];
  }

  componentDidMount() {
    getPokemonIDDetailsInfo(this.seasonNum).then(
      results => {this.pokemonData = results;}
    ).then(
      () => {
        return getPairwiseUsageInfo(this.seasonNum)
      }
    ).then(
      results => {
        this.usageData = results;
      }
    ).then(
      () => {
        return getDraftOrderRosterInfo(this.seasonNum, "")
      }
    ).then(
      results => {this.rosterOrder = results;}
    ).finally(
      () => {
        this.setState({
          attackPlayer: Object.keys(this.usageData)[0]
        })
      }
    )
  }

  setAttackPlayer(newPlayer) {
    this.setState({attackPlayer: newPlayer})
  }

  generatePlayerDropdown(label, func, val) {
    const options = []
    Object.keys(this.usageData).forEach(value => {
        options.push(<option key={value} value={value}>
            {value}
        </option>)
    })
    return <div className="player-dropdown-div">
        <label>{label}:&nbsp;
        <select name={"gameSelector"}
            value={val}
            onChange={(e) => func(e.target.value)}>
            {options}
        </select>
    </label> 
    </div>
  }

  generateColorToggleButton() {
    return <div className="player-dropdown-div">
    <button className="btn btn-dark" onClick={(e) => {this.setState({colorByUsage: !this.state.colorByUsage})}}>
      Color by {this.state.colorByUsage ? "Usage" : "Win %"}
    </button>
    </div>
  }

  generateTeamAnalysisTable() {
    if (checkEmptyObject(this.usageData)) {
        return <div>Hello World!</div>
    }
    const attackRoster = this.rosterOrder[this.state.attackPlayer].roster;
    const playerUsage = this.usageData[this.state.attackPlayer];

    // Header is the pokemon icons
    const tableHeader = [<th></th>]
    attackRoster.forEach(pokeID => {
      const pokeInfo = this.pokemonData.base[pokeID];
      const pokeSprite = <img className="pokeHeaderImg" src={pokeInfo.dex_sprite_url} alt="doot" key={pokeID} />
      tableHeader.push(<th>{pokeSprite}</th>)
    })

    const tableRows = []
    attackRoster.forEach(rowPoke => {
      const rowPokeInfo = this.pokemonData.base[rowPoke];
      const pokeRow = [<td>{<img className="pokeHeaderImg" src={rowPokeInfo.dex_sprite_url} alt="doot" key={rowPoke} />}</td>];
      const pokePairwiseUsage = playerUsage[rowPoke];
      if (pokePairwiseUsage === undefined) {
        return;
      }

      attackRoster.forEach(colPoke => {
        let cellUsage = pokePairwiseUsage[colPoke]
        if (cellUsage === undefined) {
          pokeRow.push(<td className="usageCell lossCell">
            <b>Games:</b> 0 (0%)<br/>
            <b>Win:</b> N/A
          </td>)
          return
        }
        if (rowPoke === colPoke) {
          pokeRow.push(<td className="usageCell">---</td>)
          return
        }


        const colorVar = (this.state.colorByUsage ? "cond_usage" : "win_percent")
        let colorLabel = "lossCell";
        if (cellUsage[colorVar] >= this.state.gyThreshold) {
          colorLabel = "winCell";
        } else if (cellUsage[colorVar] >= this.state.yrThreshold) {
          colorLabel = "neutralCell"
        } 

        pokeRow.push(<td className={"usageCell " + colorLabel} >
          <b>Games:</b> {cellUsage.total} ({ Math.round(100 * cellUsage.cond_usage) + "%"})<br/>
          <b>Wins:</b> {cellUsage.wins} ({ Math.round(100 * cellUsage.win_percent) + "%" })
          </td>)
      })
      tableRows.push(<tr>{pokeRow}</tr>)
    })


    return <div>
        <table className="league-table table-responsive table-striped">
        <thead>
        <tr>
            {tableHeader}
        </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </table>
    </div>

  }

  render() {
    return (
      <div className="about">
        <div className="container-fluid h-100">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <h1 className="font-weight-light">Season #{ this.seasonNum } Pairwise Usage Statistics</h1>
              {this.generatePlayerDropdown("Player Roster", this.setAttackPlayer, this.state.attackPlayer)} &nbsp;
              {this.generateColorToggleButton()}
              <div className="analyzer-table-div">
                  <div className="table-container">
                  {this.generateTeamAnalysisTable()}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default PairwiseUsage;