// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.pokeHeaderImg {
    width: 28px;
}

td.usageCell {
    font-size: 8pt;
    white-space: nowrap; 
    padding-top: 5px;
    padding-bottom: 5px;
}

td.lossCell {
    background-color: red;
}

td.neutralCell {
    background-color: yellow;
}

td.winCell {
    background-color: lightgreen;
}`, "",{"version":3,"sources":["webpack://./src/component_css/PairwiseUsage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["img.pokeHeaderImg {\n    width: 28px;\n}\n\ntd.usageCell {\n    font-size: 8pt;\n    white-space: nowrap; \n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n\ntd.lossCell {\n    background-color: red;\n}\n\ntd.neutralCell {\n    background-color: yellow;\n}\n\ntd.winCell {\n    background-color: lightgreen;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
