// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyzer-table-div th {
    font-size: 16pt;
}

.analyzer-table-div td {
    font-size: 13pt;
}

.player-dropdown-div {
    display: inline-block;
}

.type-dropdown-div {
    color: red;
    font-size: 10pt;
}`, "",{"version":3,"sources":["webpack://./src/component_css/TeamAnalyzer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".analyzer-table-div th {\n    font-size: 16pt;\n}\n\n.analyzer-table-div td {\n    font-size: 13pt;\n}\n\n.player-dropdown-div {\n    display: inline-block;\n}\n\n.type-dropdown-div {\n    color: red;\n    font-size: 10pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
