import React from "react";
// import configData from "../config.json";
import {constructLeagueConfigs} from './helpers';

const configData = constructLeagueConfigs();

function BasePage() {
    const seasonKeys = configData.SEASONS_ORDER.filter(x => {
      return Object.keys(configData.LEAGUE_CONFIG).includes(x)
    })

    
    const seasonLinks = []
    seasonKeys.forEach(function(seasonKey) {
      const seasonConfig = configData.LEAGUE_CONFIG[seasonKey]
      seasonLinks.push(
        <li key={seasonKeys}>
          <a href={"/".concat(seasonKey)}>
          {(seasonConfig.MAIN_HEADER === undefined ? 
            "QPL Season #".concat(seasonKey): seasonConfig.MAIN_HEADER)}
          </a>
        </li>
      )
    })

    return (
      <div className="basepage">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
          <h1>Wow some text goes here</h1>
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Please select a QPL season from below</h1>
              <ul>
                {seasonLinks}
              </ul>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default BasePage;