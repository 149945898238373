// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.display-list ol {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0.5rem;
    font-size: 9pt;
    white-space: nowrap;
}

td.usage-list-td {
    text-align: left;
    font-size: x-small;
    margin: 0;
}

div.display-list {
    display: inline-block;
    margin-left: 15%;
    padding: 0;
}

div.sort-button-bar {
    margin: auto;
    display: table;
    /* width: 90%; */
    padding-bottom: 5px;
    padding-top: 5px;
}

div.threshold-limit-div {
    vertical-align: middle;
    display: flex;
    display: -ms-flexbox;
}

label.gut-label {
    padding: 0;
    margin: auto;
    display: inline-block;
}

td.counter-td {
    font-weight: 600;
    font-size: x-large;
}

table.battlestats-table td {
    font-size: 10pt;
}

table.battlestats-table th {
    font-size: 12pt;
}`, "",{"version":3,"sources":["webpack://./src/component_css/BattleStats.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["div.display-list ol {\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n    padding-left: 0;\n    padding-right: 0.5rem;\n    font-size: 9pt;\n    white-space: nowrap;\n}\n\ntd.usage-list-td {\n    text-align: left;\n    font-size: x-small;\n    margin: 0;\n}\n\ndiv.display-list {\n    display: inline-block;\n    margin-left: 15%;\n    padding: 0;\n}\n\ndiv.sort-button-bar {\n    margin: auto;\n    display: table;\n    /* width: 90%; */\n    padding-bottom: 5px;\n    padding-top: 5px;\n}\n\ndiv.threshold-limit-div {\n    vertical-align: middle;\n    display: flex;\n    display: -ms-flexbox;\n}\n\nlabel.gut-label {\n    padding: 0;\n    margin: auto;\n    display: inline-block;\n}\n\ntd.counter-td {\n    font-weight: 600;\n    font-size: x-large;\n}\n\ntable.battlestats-table td {\n    font-size: 10pt;\n}\n\ntable.battlestats-table th {\n    font-size: 12pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
