// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-conference {
    font-weight: 600;
    /* border: 3px solid; */
    /* padding: 3px; */
}

.active-player {
    font-weight: 600;
    /* border: 3px solid; */
    /* padding: 3px; */
}

.usage-stats-legend {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.usage-stats-legend:hover {
    cursor: pointer;
}

.league-table th.clickable-legend-header {
    font-size: 20pt;
}

.usage-stats-legend-container {
    margin-bottom: 15px;
}

.usage-stats-table-div th {
    font-size: 16pt;
    padding-left: 10px;
    padding-right: 10px;
    display: table-cell;
}

.usage-stats-legend td {
    font-size: 14pt;
    padding-top: 2px;
    padding-bottom: 2px;
}

.usage-stats-table-div .tradelog-cell {
    font-size: 12pt;
}

.usage-stats-table-div .pokemon-stats {
    font-size: 10pt;
}

.player-name-span {
    padding: 2px;
}

.player-name-span:hover {
    cursor: pointer;
}

.sort-button-bar {
    margin: auto;
    /* width: 90%; */
    padding-bottom: 5px;
}

.btn-group {
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/component_css/UsageStats.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".active-conference {\n    font-weight: 600;\n    /* border: 3px solid; */\n    /* padding: 3px; */\n}\n\n.active-player {\n    font-weight: 600;\n    /* border: 3px solid; */\n    /* padding: 3px; */\n}\n\n.usage-stats-legend {\n    width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n    display: table;\n}\n\n.usage-stats-legend:hover {\n    cursor: pointer;\n}\n\n.league-table th.clickable-legend-header {\n    font-size: 20pt;\n}\n\n.usage-stats-legend-container {\n    margin-bottom: 15px;\n}\n\n.usage-stats-table-div th {\n    font-size: 16pt;\n    padding-left: 10px;\n    padding-right: 10px;\n    display: table-cell;\n}\n\n.usage-stats-legend td {\n    font-size: 14pt;\n    padding-top: 2px;\n    padding-bottom: 2px;\n}\n\n.usage-stats-table-div .tradelog-cell {\n    font-size: 12pt;\n}\n\n.usage-stats-table-div .pokemon-stats {\n    font-size: 10pt;\n}\n\n.player-name-span {\n    padding: 2px;\n}\n\n.player-name-span:hover {\n    cursor: pointer;\n}\n\n.sort-button-bar {\n    margin: auto;\n    /* width: 90%; */\n    padding-bottom: 5px;\n}\n\n.btn-group {\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
