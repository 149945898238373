import React from "react";
// import configData from "../config.json";
import '../component_css/Roster.css';

import { capitalizeFirstLetter, getPlayerAccounts } from './helpers';
import PokemonDetailsPage from './PokemonDetailsPage';

import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


class BaseRosterDraftPage extends PokemonDetailsPage {
  constructor() {
    super();
    this.state = {
      activePokemon: [],
      detailsView: false,
      australiaView: false,
      landingPageText: "pew pew",
      sortKey: "draft"
    }
    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum]
    this.triggerAustraliaView = this.triggerAustraliaView.bind(this);
    this.playerAccounts = undefined;

    this.pageData = {}
  }

  triggerAustraliaView() {
    this.setState({
      australiaView: !this.state.australiaView
    })
  }

  componentDidMount() {
    this.getPokemonInfo().then(
      results => {this.pokemonInfo = results;}
      ).then(
        () => {
          if (window.location.search != "") {
            return this.getTableData()
          } else {
            return {}
          }
        }
      ).then(
        (results) => {
          this.pageData = results;
        }
      ).then(
        () => {return getPlayerAccounts(this.seasonNum)}
      ).then(
        results => {this.playerAccounts = results;}
      ).finally(
        // Force rerender
        () => {
          this.setState({})
        }
      );
  }

  getTableData() {
    throw Error("Not Implemented yet...")
  }

  generateLandingPage(keyword) {
    const rosterLinks = [];
    for (let [key, value] of Object.entries(this.seasonConfig.ROSTER.CONFERENCE)) {
      rosterLinks.push(<div className="col-sm-5 roster-option">
        <a href={"?conference=" + key}>
          <img className="roster-img" src={value.displayImgSRC} alt={key + "Conference Image"} />
          <h4 className="roster-img-title">{value.displayName + capitalizeFirstLetter(this.state.landingPageText)}</h4>
          <div className="overcolor"></div>
        </a>
        
      </div>)
    }

    return <div className="col-lg-12">
      <div className="row h-100 justify-content-center">
        {rosterLinks}
      </div>
      <h2 className="title-small" style={{textAlign: "center"}}>Please choose conference {keyword} to view</h2>
    </div>
  }

  generateTriggerButtons() {
    return <>
      {this.generateDetailsButton()}

      <button className="triggerButton btn btn-dark" onClick={this.triggerAustraliaView}>
        {(this.state.australiaView ? "U.S.A" : "Australia") + " View"}
      </button>
    </>
  }

  generateMainBodyTable() {
    return <div></div>
  }

  render() {
    let body = <div></div>
    let triggerButton = <span></span>
    if (window.location.search === "") {
      body = this.generateLandingPage(this.state.landingPageText)
    } else {
      body = this.generateMainBodyTable()
      triggerButton = <div className="row align-items-center button-bar">
        {this.generateTriggerButtons()}
      </div>
    }

    return ( 
      <div className="about">
        <div className="container-fluid">
          <div className="row  my-5">
            <div className="col-lg-12">
              {/* <h1 className="font-weight-light">Roster</h1> */}
              <div className="container-fluid h-100">
                
                {triggerButton}
                {body}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default BaseRosterDraftPage;