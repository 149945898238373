import React, { /*useState*/ } from "react";
import '../component_css/GameData.css';
import { parseSeasonFromPath, isNumber, getGameData} from './helpers';
// import configData from "../config.json";
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();



function getSortedIndices(arrayToSort) {
  const sortedArray = JSON.parse(JSON.stringify(arrayToSort));
  sortedArray.sort()
  const sortedIndex = [];
  sortedArray.forEach(val => {
    sortedIndex.push(arrayToSort.indexOf(val))
  })
  return sortedIndex
}


class GameData extends React.Component {
  constructor() {
    super()

    this.buildTable = this.buildTable.bind(this)
    this.changeSearchTextPlayer = this.changeSearchTextPlayer.bind(this);
    this.changeSearchTextPokemon = this.changeSearchTextPokemon.bind(this);
    this.changeSearchRoundNum = this.changeSearchRoundNum.bind(this);
    this.changeGameType = this.changeGameType.bind(this);
    this.triggerSpoilerView = this.triggerSpoilerView.bind(this);

    this.seasonConfig = configData.LEAGUE_CONFIG[parseSeasonFromPath(window.location.pathname)]
    this.seasonNum = parseSeasonFromPath(window.location.pathname)

    this.state = {
      data: [],
      searchTextPlayer: "",
      searchTextPokemon: "",
      searchRoundNum: "",
      gameType: "REGULAR",
      spoilerView: false,
    }
  }

  changeSearchTextPlayer(e) {
    e.preventDefault();
    this.setState({
      searchTextPlayer: e.currentTarget.value.toLowerCase()
    })
    console.log("Player " + e.currentTarget.value)
  }

  changeSearchTextPokemon(e) {
    e.preventDefault();
    this.setState({
      searchTextPokemon: e.currentTarget.value.toLowerCase()
    })
    console.log("Pokemon " + e.currentTarget.value)
  }

  changeSearchRoundNum(e) {
    e.preventDefault()
    if (!isNumber(e.currentTarget.value)) {
      return
    }
    this.setState({searchRoundNum: e.currentTarget.value})
  }

  changeGameType(newType) {
    if (newType === this.state.gameType) {
      return
    }
    getGameData(this.seasonNum, newType).then(
      results => {
        this.setState({
          gameType: newType,
          data: results
        })
      }
    )
  }

  filterPlayerData(listToFilter) {
    if (this.state.searchTextPlayer === "") return listToFilter;

    let foundExact = false;
    listToFilter.forEach((datum) => {
      if (datum.player1.toLowerCase() === this.state.searchTextPlayer || datum.player2.toLowerCase() === this.state.searchTextPlayer) {
        foundExact = true;
      }
    });

    return listToFilter.filter(datum => {
      if (foundExact) {
        return datum.player1.toLowerCase() === this.state.searchTextPlayer || datum.player2.toLowerCase() === this.state.searchTextPlayer
      }
      return datum.player1.toLowerCase().includes(this.state.searchTextPlayer) || datum.player2.toLowerCase().includes(this.state.searchTextPlayer)
    });
  }

  filterRoundData(listToFilter) {
    if (this.state.searchRoundNum === "") {
      return listToFilter
    }
    const filtRound = Number(this.state.searchRoundNum);
    return listToFilter.filter(datum => {
      return datum.round === filtRound;
    })
  }

  filterPokemonData(listToFilter) {
    if (this.state.searchTextPokemon === "") return listToFilter;

    let foundExact = false;
    listToFilter.forEach((datum) => {
      const rosterLC = datum.player1_roster.map(x => x.toLowerCase())
      rosterLC.push(...datum.player2_roster.map(x => x.toLowerCase()))

      if (rosterLC.includes(this.state.searchTextPokemon)) {
        foundExact = true;
      }
    });

    return listToFilter.filter(datum => {
      const rosterLC = datum.player1_roster.map(x => x.toLowerCase())
      rosterLC.push(...datum.player2_roster.map(x => x.toLowerCase()))

      if (foundExact) {
        return rosterLC.includes(this.state.searchTextPokemon);
      } else {
        let match = false;
        rosterLC.forEach(gamePokemon => {
          match = match || gamePokemon.includes(this.state.searchTextPokemon);
        });
        return match;
      }
    });
  }

  buildTable() {
    const tableRows = []
    let spoilClass = "";
    if (!this.state.spoilerView) {
      spoilClass = "nospoilers"
    }

    let filtered = this.filterPlayerData(this.state.data);
    filtered = this.filterPokemonData(filtered);
    filtered = this.filterRoundData(filtered);

    filtered.forEach((datum, i) => {
      let game_link_elt = <span></span>;
      let game_details_elt = <span></span>
      if (datum.date === null | datum.date === undefined) {/* Do nothing */}
      else if (datum.replay_url !== "#") {
        game_link_elt = <a href={datum.replay_url} rel="noreferrer" target="_blank">Link</a>
        game_details_elt = <a href={"/" + this.seasonNum + "/gamedetails/" + datum.game_url_id}>Link</a>
      } else {
        game_link_elt = <span>Link Unavailable</span>
        game_details_elt = <span>Link Unavailable</span>
      }

      let p1Class = "";
      let p2Class = "";
      if (datum.player1_remaining > datum.player2_remaining && this.state.spoilerView) {
        p1Class = "winner"
      }
      if (datum.player1_remaining < datum.player2_remaining && this.state.spoilerView) {
        p2Class = "winner"
      }

      // Prepare the player rosters
      const player1_roster_imgs = [];
      const player2_roster_imgs = [];

      getSortedIndices(datum.player1_roster).forEach(idx => {
        player1_roster_imgs.push(
          <img src={datum.player1_roster_icons[idx]}
               alt={datum.player1_roster[idx]}
               title={datum.player1_roster[idx]} />
        )
      })

      getSortedIndices(datum.player2_roster).forEach(idx => {
        player2_roster_imgs.push(
          <img src={datum.player2_roster_icons[idx]}
               alt={datum.player2_roster[idx]}
               title={datum.player2_roster[idx]} />
        )
      })

      let player1 = datum.player1
      let player2 = datum.player2
      if (!this.state.spoilerView) {
        let coin = Math.round(Math.random())
        if (coin) {
          player1 = datum.player2
          player2 = datum.player1 
        }
      }

      tableRows.push(
        <tr className="game-data-rows" 
          key={datum.date + datum.round + i}>
            <td>{datum.date}</td>
            <td>{datum.round}</td>

            <td className={p1Class}>{player1}</td>
            <td className={p2Class}>{player2}</td>
            
            <td className={"roster-imgs " + spoilClass}>{player1_roster_imgs}</td>
            <td className={"roster-imgs "+ spoilClass}>{player2_roster_imgs}</td>

            <td className={spoilClass}>{datum.player1_remaining}</td>
            <td className={spoilClass}>{datum.player2_remaining}</td>
            <td>{game_link_elt}</td>
            <td>{game_details_elt}</td>
        </tr>
      )
    })

    const output = <table className="game-data-table table-responsive table-striped">
      <thead>
        <tr>
          <th>Date</th>
          <th>Round</th>
          <th>Player 1</th>
          <th>Player 2</th>
          <th className={spoilClass}>Player 1 Roster</th>
          <th className={spoilClass}>Player 2 Roster</th>
          <th className={spoilClass}>Player 1 Remaining</th>
          <th className={spoilClass}>Player 2 Remaining</th>
          <th>Replay Link</th>
          <th>Details Link</th>
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  
    return output
  }

  generateGameTypeButton() {
    return <div className="row align-items-center button-bar">
      <div className="btn-toolbar sort-button-bar">
      <div className="btn-group">
      <button onClick={() => {this.changeGameType("REGULAR")}} className={"triggerButton btn " + (this.state.gameType === "REGULAR" ? "btn-primary" : "btn-dark") }>
        Regular Season
      </button>
      <button onClick={() => {this.changeGameType("PLAYOFFS")}} className={"triggerButton btn " + (this.state.gameType === "PLAYOFFS" ? "btn-primary" : "btn-dark") }>
        Playoffs
      </button>
    </div></div>
    </div>
  }

  triggerSpoilerView() {
    this.setState({
      spoilerView: !this.state.spoilerView
    })
  }

  generateTriggerButtons() {
    return <>
      <button className="triggerButton btn btn-dark" onClick={this.triggerSpoilerView}>
        {(this.state.spoilerView ? "Hide" : "Show") + " Spoilers"}
      </button>
    </>
  }

  getTableData(seasonNum) {
    const api_endpoint = configData.SERVER_URL + seasonNum + "/gamedata"  + window.location.search;
    return fetch(api_endpoint)
    .then(res => res.json(),
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        alert(error);
      })
    .then(
      results => {
        if (results === undefined) {
          return;
        }
        this.setState({
          data: results,
          "seasonNum": seasonNum
        });
      },
      (error) => {
        this.setState({
          data: [],
          "seasonNum": seasonNum
        });
      }
    )
    .catch(error => console.warn(error));
  }

  componentDidMount() {
    getGameData(this.seasonNum, this.state.gameType).then(
      results => {
        this.setState({data: results})
      }
    )
  }

  handleSubmit(seasonNum, e) {
    e.preventDefault();
    const replayLink = e.target[0].value;
    // const replayFile = e.target[1].files[0];
    // const replayFileLink = e.target[2].value;
    const replayFile = undefined;
    const replayFileLink = undefined;
    if (replayLink === "" & replayFile === undefined) {
      // Nothing submitted, return
      alert("Please provide a replay link.")
      return;
    }
    // if (replayFile !== undefined & replayFileLink === "") {
    //   // Submit file without link, this is invalid
    //   alert("Please provide a link with where your replay file can be accessed")
    //   return;
    // }

    // Prepare the data for the request
    var reqData = new FormData()
    reqData.append('file', replayFile)
    reqData.append('file_link', replayFileLink)
    reqData.append('link', replayLink)

    // Send the request to the proper endpoint
    const api_endpoint = configData.SERVER_URL + seasonNum + "/gamedata/submit?gametype="  + this.state.gameType;
    return fetch(api_endpoint, {
      method: "POST",
      body: reqData
    })
    .then(res => {
      if (res.status !== 200) {
        throw res;
      }
      alert("Successfully uploaded replay. Please verify in the table.")
    },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        alert(error);
      }).then(
        () => {return getGameData(this.seasonNum, this.state.gameType)}
      ).then(
        results => {
          this.setState({data: results})
        }
      )
    .catch((error) => {
      console.warn(error);
      alert("There seems to have been a server error.\nPlease double check (1) you are providing a valid replay and (2) that it is not a duplicate.");
    });
  }

  render() {  
    let body = [];

    let bracketDiv = <div></div>
    let submitClassname = "left-right"
    const bracketLink = this.seasonConfig.GAMEDATA.PLAYOFFS.bracketLink
    let triggerButton = <span></span>
    triggerButton = <div className="spoilerButtonDiv">
        {this.generateTriggerButtons()}
      </div>
    if (bracketLink && this.state.gameType === "PLAYOFFS") {
      submitClassname = "left"
      bracketDiv = <div className="right">
        <div className="bracket-container">           
          <iframe  title="playoff-bracket" src={bracketLink} width="100%" height="500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
        </div>
      </div>
    }
    const table = this.buildTable();
    const submitGameSection = <div className="game-data-submit-container">
      <div className = {submitClassname}>
        <h1 className="font-weight-light">Submit a Game</h1>
        <form className="row" onSubmit={(e) => this.handleSubmit(this.seasonNum, e)}>
          <div className="col-sm-6">
            Provide the Showdown replay link <br/>
            <input type="text" name="replayLink"/> 
          </div>
        {/* No Longer allowing this */}
        {/* <div className="or">-OR-</div>
          <div className="col-sm-6">
            Upload the replay file yourself <br/>
            <input type="file" name="fileUpload"/>
            <br/>
            Please provide a link (e.g. Google Drive) for this file <br/>
            <input type="text" name="fileReplayLink"/>
          </div> */}
          <input className="form-submit-btn" type="submit" value="Submit" />
        </form>
      </div>
      {bracketDiv}
    </div>

    const gameDataSection = <div>
      <h1 className="font-weight-light">Game Data</h1>
      <h2 className="title-small"> Season #{ this.seasonNum } Game Data </h2>
      <input type="text" className="game-data search" placeholder="Search by Player" onChange={this.changeSearchTextPlayer}/> &nbsp;
      <input type="text" className="game-data search" value={this.state.searchRoundNum} placeholder="Search by Round" onChange={this.changeSearchRoundNum}/> &nbsp;
      <input type="text" className="game-data search" placeholder="Search by Pok&eacute;mon" onChange={this.changeSearchTextPokemon}/> &nbsp;
      {triggerButton}
      <div className="game-data-table-surrounds">
        {table}
      </div>
    </div>
    body.push(submitGameSection, gameDataSection)


    return (
      <div className="about">
        <div className="container-fluid">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <div>{this.generateGameTypeButton()}</div>
              {body}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GameData;
