import React from "react";
import { parseSeasonFromPath, isNumber, getBattleStatsData, constructLeagueConfigs} from './helpers';
import PokemonDetailsPage from "./PokemonDetailsPage";
import "../component_css/BattleStats.css"
import "../component_css/UsageStats.css"

const configData = constructLeagueConfigs();

class BattleStats extends PokemonDetailsPage {
    constructor() {
        super()
        this.seasonNum = parseSeasonFromPath(window.location.pathname)
        this.statsData = {
          "pokemon": [],
          "player": {}
        }
        this.state = {
            displayLevel: "total",
            sortingKey: "total_used",
            sortOrderDesc: true,
            thresholdLimit: 0,
            searchPokemon: "",
            detailsView: false,
            activePokemon: [],
            iconView: true,
            dataSelectionKey: "pokemon",
            activePlayer: ""
        }
        this.setDisplayLevel = this.setDisplayLevel.bind(this);
        this.updateSortingOrder = this.updateSortingOrder.bind(this);
        this.updateSortingVariable = this.updateSortingVariable.bind(this);
        this.updateThresholdLimit = this.updateThresholdLimit.bind(this);
        this.updateSearchPokemon = this.updateSearchPokemon.bind(this);
        this.updateActive = this.updateActive.bind(this);
        this.setChosenPlayer = this.setChosenPlayer.bind(this);

    }

    setChosenPlayer(newPlayer) {
      if (newPlayer === "") {
        this.setState({
          dataSelectionKey: "pokemon",
          activePlayer: ""
        })
      } else {
        this.setState({
          dataSelectionKey: "player",
          activePlayer: newPlayer
        })
      }
    }

    setDisplayLevel(newLevel) {
      this.setState({
        displayLevel: newLevel
      })
    }

    // TODO: Put these updates into their own field
    updateThresholdLimit(event) {
        let newLimit = event.target.value
        if (isNumber(newLimit)) {
            newLimit = parseInt(newLimit);
            if (newLimit >= 0) {
                this.setState({thresholdLimit: newLimit})
            } else {
                this.setState({thresholdLimit: 0})
            }
        }
    }

    updateSearchPokemon(event) {
      this.setState({searchPokemon: event.target.value})
    } 

    componentDidMount() {
      this.getPokemonInfo().then(
            results => {
              this.pokemonInfo = results;
            }).then(
              () => {
                return getBattleStatsData(this.seasonNum)
              }
            ).then(
            results => {
              this.statsData = results;
            }
          ).finally(
            // Force a re-render
            () => {
              this.setState(this.state)
            }
          )
    }

    sortPokemonData(pokemonDataArray) {
        if (pokemonDataArray.length === 0) return pokemonDataArray;

        const mapVariables = {
            dmg_dealt: {
                true: "total",
                false: "rate"
            },
            dmg_received: {
                true: "total",
                false: "rate"
            },
            turns_count: {},
            faint: {
                true: "total",
                false: "rate"
            },
            kos: {
                true: "total",
                false: "rate"
            },
            lead: {
                true: "total",
                false: "rate"
            },
            transformation: {
                true: "total",
                false: "rate"
            }
        }

        // Infer the type we're supposed to deal with
        let refType = typeof(pokemonDataArray[0][this.state.sortingKey]);
        let subVarTrigger = false;
        let subKey = undefined;
        if (Object.keys(mapVariables).indexOf(this.state.sortingKey) !== -1) {
            // TODO: CHANGE THIS
            subKey = this.state.displayLevel;
            refType = typeof(pokemonDataArray[0][this.state.sortingKey][subKey]);
            subVarTrigger = true;
        }

        if (refType === "number") {
          pokemonDataArray = pokemonDataArray.sort((a, b) => {
            let bVal = b[this.state.sortingKey]
            let aVal = a[this.state.sortingKey]

            if (subVarTrigger) {
                bVal = b[this.state.sortingKey][subKey]
                aVal = a[this.state.sortingKey][subKey]
            }

            // Nulls should be below everything
            if (bVal === null && bVal === aVal) {
              bVal = 0;
              aVal = 0
            } else if (bVal === null) {
              bVal = aVal - 1
            } else if (aVal === null) {
              aVal = bVal - 1
            }
            let valDiff = bVal - aVal;
    
            // If we have a difference, use this
            if (valDiff !== 0) {
              return valDiff
            }
            // Otherwise just sort alphabetically
            return -1 * b["pokemon_name"].localeCompare(a["pokemon_name"])
          })
        } else if (refType === "string") {
          pokemonDataArray = pokemonDataArray.sort((a, b) => a[this.state.sortingKey].localeCompare(b[this.state.sortingKey]))
        }
    
        if (this.state.sortOrderDesc !== true) {
          pokemonDataArray = pokemonDataArray.reverse();
        }
    
        return pokemonDataArray
      }

    updateSortingVariable(newKey) {
        this.setState(
            {sortingKey: newKey}
        )
    }

    updateSortingOrder(boolValue) {
        this.setState({
          sortOrderDesc: boolValue
        })
      }

    updateActive(newKey) {
      const oldState = this.state;
      const index = oldState.activePokemon.indexOf(newKey);
      if (index > -1) {
        oldState.activePokemon.splice(index, 1);
      } else {
        oldState.activePokemon.push(newKey)
      }
      this.setState(oldState);
    }

    generatePlayerDropdown() {
      const playerDropdownOpts = [];
      if (Object.keys(this.statsData).length !== 0) {
        Object.keys(this.statsData["player"]).forEach(playerName => {
          playerDropdownOpts.push(
            <option value={playerName} key={playerName}>{playerName}</option>
          )
        })
      }
      return <span >&nbsp;
          <label>Chosen Player: &nbsp;
            <select value={this.state.activePlayer}
              onChange={(e) => this.setChosenPlayer(e.target.value)}>
              <option value=""></option>
              {playerDropdownOpts}
            </select>
          </label>
        </span>
    }

    buildTable() {
        let statsData = []
        if (Object.keys(this.statsData).length !== 0) {
          if (this.state.dataSelectionKey === "pokemon") {
            statsData = this.sortPokemonData(this.statsData[this.state.dataSelectionKey]);
          } else {
            statsData = this.sortPokemonData(this.statsData[this.state.dataSelectionKey][this.state.activePlayer]);
          }
        } 
        const thresholdLimit = this.state.thresholdLimit;
        statsData = statsData.filter(datum => {
            return datum["total_used"] >= thresholdLimit
        }).filter(
          datum => {
            if (this.state.searchPokemon === "") {return true}
            else {
              return datum["pokemon_name"].toLowerCase() === this.state.searchPokemon.toLowerCase() |
              datum["pokemon_name"].toLowerCase().includes(this.state.searchPokemon.toLowerCase())
            }
          }
        );

        const tableHeader = []
        const dispLevel = this.state.displayLevel
        const seasonNum = this.seasonNum
        configData.BATTLE_STATS.baseHeader.forEach(function (obj) {
          if (!obj.validDisplayLevels.includes(dispLevel)) return
          if (configData.LEAGUE_CONFIG[seasonNum].BATTLE_STATS.ignoreColumns.includes(obj._id)) return
          if (obj.title) {
            tableHeader.push(<th title={obj.title}>{obj.name}</th>)
          } else {
            tableHeader.push(<th>{obj.name}</th>)
          }
        });

        const tableRows = []
        let counter = 1
        const iconView = this.state.iconView;
        const genIconCellFunc = this.generatePokemonIconCell;
        const genSpriteCellFunc = this.generatePokemonSpriteCell;
        statsData.forEach(datum => {
          const pokemonID = datum["pokemon_id"]
          const temp = []

          for (let iter = 0; iter < configData.BATTLE_STATS.baseHeader.length; ++iter) {
            const obj = configData.BATTLE_STATS.baseHeader[iter];
            if (!obj.validDisplayLevels.includes(dispLevel)) continue
            if (configData.LEAGUE_CONFIG[this.seasonNum].BATTLE_STATS.ignoreColumns.includes(obj._id)) continue

            if (obj._id === "rank") {
              // Rank is special
              temp.push(<td className="counter-td">{counter}</td>)
              counter += 1
            } else if (obj._id === "pokemon") {
              // Pokemon is special
              if (iconView) {
                temp.push(<td>{genIconCellFunc(pokemonID, pokemonID)}</td>)
              } else {
                temp.push(<td>{genSpriteCellFunc(pokemonID, pokemonID)}</td>)
              }
            } else if (obj._id === "move_usage") {
              // Move usage is special
              let movesUsed = []
              for (let idx = 0; idx < datum["moves_used"].length; ++idx) {
                const moveDatum = datum["moves_used"][idx];
                movesUsed.push(<li key={moveDatum["name"]}>
                  {moveDatum["name"]} ({moveDatum["num"]})
                </li>)
              }
              temp.push(<td className="usage-list-td">
                <div className="display-list">
                  <ol>
                    {movesUsed}
                  </ol>
                </div>                
              </td>)
            } else if (obj._id === "tera_usage") {
              let teraUsed = []
              for (let idx = 0; idx < datum["terastallize_used"].length; ++idx) {
                const teraDatum = datum["terastallize_used"][idx];
                const typeName = teraDatum.name;
                teraUsed.push(<li key={typeName}>
                  <img className="type-img"
                        src={configData["TYPE_IMAGES"][typeName]}
                        key={typeName}
                        alt={"[" + typeName + "]"} /> ({teraDatum.num})
                </li>)
              }
              temp.push(<td className="usage-list-td">
                <div className="display-list">
                  <ol>
                    {teraUsed}
                  </ol>
                </div>
              </td>)

            } else {
              // Rest of them are not special

              // First extract the value
              let objVal = datum[obj.datumKey];
              if (typeof objVal === 'object') {
                if (Object.keys(objVal).includes(this.state.displayLevel) &&
                  objVal[this.state.displayLevel] !== null) {
                    objVal = objVal[this.state.displayLevel]
                } else {
                  objVal = objVal["total"]
                }
              }

              // Format it properly: rounding
              var text = 0;
              if (obj.round) {
                text = Math.round(100 * objVal) / 100
              } else {
                text = objVal
              }

              // Format it properly: percent
              if (obj.percent) {
                text = Math.trunc(100 * text) + "%";
              } else {
                text = Math.trunc(100 * text) / 100
              }

              // I lied transformation is special
              if (obj._id === "transf_rate" || obj._id === "num_transf") {
                if (this.pokemonInfo.transf[pokemonID] === undefined) {
                  text = "--";
                }
              }
              console.log(text)

              temp.push(<td>{text}</td>)
            }
          }

          // Finished
          tableRows.push(<tr>{temp}</tr>)
        })

        return <table className="league-table battlestats-table table-responsive table-striped">
            <thead>
                <tr>
                    {tableHeader}
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </table>
    }

    generateSortingButtons() {
        const sortNames = ["Pokémon", "Games Used", "Total Turns", "Lead", "Damage Dealt", "K.O's", "Damage Received", "Faints"]
        const sortVariables = ["pokemon_name", "total_used", "turns_count", "lead", "dmg_dealt", "kos", "dmg_received", "faint"]

        if (this.state.displayLevel === "turn") {
          sortNames.splice(7, 1) // 7 is Faints
          sortVariables.splice(7, 1)
          sortNames.splice(5, 1) // 7 is KOs
          sortVariables.splice(5, 1)
          sortNames.splice(3, 1) // 3 is Leads
          sortVariables.splice(3, 1)
          
        }

        const buttons = []
        for (let i = 0; i < sortVariables.length; ++i) {
          let sortVar = sortVariables[i];
          let sortName = sortNames[i];
          let btnClass = "btn";
          if (sortVar === this.state.sortingKey) {
            btnClass = btnClass.concat(" btn-primary")
          } else {
            btnClass = btnClass.concat(" btn-dark")
          }
    
          buttons.push(<button
            onClick={() => {this.updateSortingVariable(sortVar)}}
            key={sortVar}
            type="button"
            className={btnClass}>
              {sortName}</button>)
        }

        return <div className="btn-toolbar sort-button-bar">
            <div className="btn-group" role="group" aria-label="Basic example">
                {buttons}
            </div>
            <div className="btn-toolbar sort-button-bar" role="toolbar">
                <div className="btn-group" role="group" aria-label="Third group">
                    <button type="button" onClick={() => this.updateSortingOrder(false)} className={"btn " + (this.state.sortOrderDesc ? "btn-dark" : "btn-primary")}>Ascending</button>
                    <button type="button" onClick={() => this.updateSortingOrder(true)} className={"btn " + (this.state.sortOrderDesc ? "btn-primary" : "btn-dark")}>Descending</button>
                </div>&nbsp;&nbsp;
                <div className="btn-group" role="group" aria-label="Third group">
                    <button type="button" onClick={() => this.setDisplayLevel("total")} className={"btn " + (this.state.displayLevel === "total" ? "btn-primary" : "btn-dark")}>Total</button>
                    <button type="button" onClick={() => this.setDisplayLevel("game")} className={"btn " + (this.state.displayLevel === "game" ? "btn-primary" : "btn-dark")}>Per Game</button>
                    <button type="button" onClick={() => this.setDisplayLevel("turn")} className={"btn " + (this.state.displayLevel === "turn" ? "btn-primary" : "btn-dark")}>Per Turn</button>
                </div>
            </div>
            <div className="btn-toolbar sort-button-bar">
            <div className="btn-group">
                  <label className="gut-label">Pok&eacute;mon Search:</label> &nbsp;
                  <input type="text" value={this.state.searchPokemon} onChange={this.updateSearchPokemon} style={{width: "150px"}} /> &nbsp;&nbsp;
                  <label className="gut-label">Minimum Games Played:</label> &nbsp;
                  <input type="text" value={this.state.thresholdLimit} onChange={this.updateThresholdLimit} style={{width: "30px"}}/>
                </div>
            </div>

            </div>
    }

    render() {
        const body = <div className="usage-stats-table-div">
          <div className="table-container">

            {this.buildTable()}
          </div>
        </div>
    
        return (
          <div className="about">
            <div className="container-fluid">
              <div className="row align-items-center my-5 ">
                <div className="col-sm-1"></div>
                <div className="col-sm-10">
                  <h1 className="font-weight-light">Season #{ this.seasonNum } Battle Stats</h1>
                  <div className="row align-items-center button-bar">
                      <div className="triggerButton">{this.generateDetailsButton()}</div>
                      <div className="triggerButton">{this.generateIconButton()}</div>
                      <div className="triggerButton">{this.generatePlayerDropdown()}</div>
                      
                  </div>
                  <div className="row align-items-center sort-button-bar">
                    {this.generateSortingButtons()}
                  </div>
          
                  {body}
                </div>
              </div>
            </div>
          </div> 
        );
      }
}

export default BattleStats;