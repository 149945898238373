import React from "react";
// import configData from "../config.json";
import "../component_css/TeamAnalyzer.css"

import { parseSeasonFromPath, checkEmptyObject, capitalizeFirstLetter,
  computeWeaknessModifier, getRosterInfo, getPokemonIDDetailsInfo } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();
  

class TeamAnalyzer extends React.Component {
  constructor() {
    super()
    this.state = {
        chosenPlayer: null
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.pokemonData = {}
    this.rosterData = {}

    this.setChosenPlayer = this.setChosenPlayer.bind(this)
    this.generatePlayerDropdown = this.generatePlayerDropdown.bind(this)
    this.generateTeamAnalysisTable = this.generateTeamAnalysisTable.bind(this)
    this.seasonConfig = configData.LEAGUE_CONFIG[this.state.seasonNum]
  }

  componentDidMount() {
    getPokemonIDDetailsInfo(this.seasonNum).then(
      results => {this.pokemonData = results;}
    ).then(
      () => {return getRosterInfo(this.seasonNum)}
    ).then(
      results => {
        this.rosterData = results;
        this.setState({
          chosenPlayer: Object.keys(results)[0]
        })
      }
    ).finally(
      // this.setState({})
    )
  }

  setChosenPlayer(newPlayer) {
    this.setState({chosenPlayer: newPlayer})
  }

  generatePlayerDropdown() {
    const options = []
    Object.keys(this.rosterData).forEach(value => {
        options.push(<option key={value} value={value}>
            {value}
        </option>)
    })
    return <div>
        <label>Chosen Player:&nbsp;
        <select name={"gameSelector"}
            value={this.state.chosenPlayer}
            onChange={(e) => this.setChosenPlayer(e.target.value)}>
            {options}
        </select>
    </label> 
    </div>
  }

  generateTeamAnalysisTable() {
    if (checkEmptyObject(this.rosterData)) {
        return <div>Hello World!</div>
    }
    const playerRoster = this.rosterData[this.state.chosenPlayer].roster;
    const weaknessList = {}
    const resistList = {}

    const types = Object.keys(configData.TYPE_RESISTANCES);

    playerRoster.forEach(pokeID => {
        const pokeInfo = this.pokemonData.base[pokeID]
        const pokeTypes = pokeInfo.types;

        types.forEach(typeName => {
            // In case we haven't seen it yet
            if (weaknessList[typeName] === undefined) {
                weaknessList[typeName] = []
                resistList[typeName] = []
            }

            const pokeWeaknessMultiplier = computeWeaknessModifier(pokeTypes, typeName);

            if (pokeWeaknessMultiplier > 1) {
                weaknessList[typeName].push(pokeInfo.dex_sprite_url)
            } else if (pokeWeaknessMultiplier < 1) {
                resistList[typeName].push(pokeInfo.dex_sprite_url)
            }

        })
    })

    const tableRows = []
    types.forEach(typeName => {
        const weaknessPokeIcons = []
        // Weakness List
        weaknessList[typeName].forEach(iconSRC => {
            weaknessPokeIcons.push(
                <img src={iconSRC} alt="doot" key={iconSRC} />
            )
        })
        if (weaknessPokeIcons.length === 0) {
            weaknessPokeIcons.push("---")
        }

        // Resistance List
        const resistPokeIcons = []
        resistList[typeName].forEach(iconSRC => {
            resistPokeIcons.push(
                <img src={iconSRC} alt="doot" key={iconSRC} />
            )
        })
        if (resistPokeIcons.length === 0) {
            resistPokeIcons.push("---")
        }

        tableRows.push(
            <tr>
                {/**  <img src={configData.TYPE_IMAGES[typeName]} alt={typeName} /> */}
                <td> {capitalizeFirstLetter(typeName)} </td>
                <td>{weaknessList[typeName].length}</td>
                <td>{weaknessPokeIcons}</td>
                <td>{resistList[typeName].length}</td>
                <td>{resistPokeIcons}</td>
            </tr>
        )
    })

    return <div>
        <h2>Type Analysis Table</h2>
        <table className="league-table table-responsive table-striped">
        <thead>
        <tr>
            <th>Type</th> <th># Weak</th><th>Pokemon Weak</th> <th># Resists</th><th>Pokemon Resist</th>
        </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </table>
    </div>

  }

  render() {

    return (
      <div className="about">
        <div className="container-fluid h-100">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <h1 className="font-weight-light">Season #{ this.seasonNum } Team Analyzer</h1>
              <p><b>Note:</b> Abilities are not taken into account</p>
              {this.generatePlayerDropdown()}
              <div className="analyzer-table-div">
                  <div className="table-container">
                  {this.generateTeamAnalysisTable()}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default TeamAnalyzer;