import React from "react";
// import configData from "../config.json";
import '../component_css/Roster.css';

import { parseSeasonFromPath, generateTypes,
  getSmogonDexLink, getSmogonDexName, 
  getPokemonIDDetailsInfo } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();
  

class PokemonDetailsPage extends React.Component {
  constructor() {
    super();
    this.seasonNum = parseSeasonFromPath(window.location.pathname)
    this.state = {
      activePokemon: [],
      detailsView: false,
      iconView: false
    }
    this.updateActive = this.updateActive.bind(this);
    this.triggerDetailsView = this.triggerDetailsView.bind(this);
    this.generateDetailsButton = this.generateDetailsButton.bind(this);
    this.triggerIconView = this.triggerIconView.bind(this);
    this.generatePokemonCell = this.generatePokemonCell.bind(this);
    this.generatePokemonSpriteCell = this.generatePokemonSpriteCell.bind(this);
    this.generatePokemonIconCell = this.generatePokemonIconCell.bind(this);

    // Display/Data
    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum]
    this.displayTierInfo = false;
    this.pokemonInfo = {};
  }

  getPokemonInfo() {
    return getPokemonIDDetailsInfo(this.seasonNum)
  }

  generateDetailsButton() {
    return <button onClick={this.triggerDetailsView} className="triggerButton btn btn-dark">
        {(this.state.detailsView ? "Hide" : "Show") + " Details"}
    </button>
  }

  generateIconButton() {
    return <button onClick={this.triggerIconView} className="triggerButton btn btn-dark">
        {(this.state.iconView ? "Show Sprites" : "Show Icons")}
    </button>
  }

  updateActive(pokeKey) {
    // pokeKey is player|Pokemon concatenated together
    const oldState = this.state;
    const index = oldState.activePokemon.indexOf(pokeKey);
    if (index > -1) {
      oldState.activePokemon.splice(index, 1);
    } else {
      oldState.activePokemon.push(pokeKey)
    }
    this.setState(oldState);
  }

  triggerDetailsView() {
    this.setState({detailsView: !this.state.detailsView})
  }

  triggerIconView() {
    this.setState({iconView: !this.state.iconView})
  }

  generatePokemonIconCell(activeKey, pokeID) {
    /* 
      See generatePokemonCell for arguments

      Generate a pokemon cell using Serebii Icons
    */
    return this.generatePokemonCell(activeKey, pokeID, "dex_sprite_url", "active-cell")
  }

  generatePokemonSpriteCell(activeKey, pokeID) {
    /* 
      See generatePokemonCell for arguments

      Generate a pokemon cell using Showdown Sprite gifs
    */
    return this.generatePokemonCell(activeKey, pokeID, "sprite_url", "tradelog-cell")
  }

  generatePokemonCell(activeKey, pokemonID, imgKey, divClass) {
    /* 
      activeKey: ID corresponding to element of this.state.activePokemon
      pokeID: Key in this.pokemonInfo.base
      imgKey: Key to determine which URL to use for sprite
      divClass: ClassName property of the resulting Div

      Generates a pokemon details cell using serebii.net icons
    */

    // CLean up arguments
    if (divClass === undefined) {
      divClass = "";
    }

    // Determine if pokemon is "active" and display transformation if they are 
    let dispKey = "base";
    let otherKey = "transf";
    if (this.state.activePokemon.includes(activeKey)) {
      dispKey = "transf";
      otherKey = "base"
    }

    // Get the data to build out cell
    let pokemonInfo = this.pokemonInfo[dispKey][pokemonID];
    let transfInfo = {}
    if (this.pokemonInfo[otherKey][pokemonID]) {
      transfInfo = this.pokemonInfo[otherKey][pokemonID]
    }

    const mainTypes = generateTypes(pokemonInfo)
    const mainPokeInfo = <span>
      <img className="trade-main-pokemon" src={pokemonInfo[imgKey]} alt={pokemonInfo.pokemon_name} />
    </span>
    var transfPokeInfo = false;
    if (transfInfo.pokemon_name) {
      transfPokeInfo = <span>[<img className="trade-mini-pokemon" src={transfInfo.dex_sprite_url} alt={transfInfo.pokemon_name} />]</span>
    }

    const abilities = [];
    for (let ability of pokemonInfo["abilities"]) {
      if (ability !== null & ability !== undefined) {
        abilities.push(ability)
      }
    }

    let pokeDetails = <span></span>
    if (this.state.detailsView) {
        pokeDetails = <div>
          <b>Abilities: </b>{abilities.join(", ")} <br/>
          <div className="pokemon-stats" >
            {pokemonInfo["stats"]["hp"]}<i> HP</i> /&nbsp;
            {pokemonInfo["stats"]["atk"]}<i> Atk</i> /&nbsp;
            {pokemonInfo["stats"]["def"]}<i> Def</i> <br />
            {pokemonInfo["stats"]["spa"]}<i> SpA</i> /&nbsp;
            {pokemonInfo["stats"]["spd"]}<i> SpD</i> /&nbsp;
            {pokemonInfo["stats"]["spe"]}<i> Spe</i> <br />
        </div>
        </div>
    }

    const smogDexLink = getSmogonDexLink(
      getSmogonDexName(pokemonInfo["sprite_url"]),
      this.seasonConfig["SMOGON_PREFIX"])

    let tierInfo = <span></span>
    if (this.displayTierInfo) {
      tierInfo = <span>| <b>Tier: </b>{pokemonInfo["tier"]}</span>
    }

    return <div className={divClass}>
      <div className="trade-cell" onClick={() => {
        if (transfPokeInfo) {
          this.updateActive(activeKey);
        }
      }}>
        {mainPokeInfo} {transfPokeInfo ? transfPokeInfo : ""} <br/>
        <b>Name: </b> <a href={smogDexLink}>{pokemonInfo.pokemon_name}</a>
      </div>
      <div><b>Type(s): </b> {mainTypes} {tierInfo} <br /></div>
      {pokeDetails}
    </div>
  }

  render() {
    return ( 
      <div className="about">
        <div className="container-fluid">
          <div className="row  my-5">
            <div className="col-lg-12">
              {/* <h1 className="font-weight-light">Roster</h1> */}
              <div className="container-fluid h-100">
                Hello World!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default PokemonDetailsPage;