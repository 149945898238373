import React from "react";
// import configData from "../config.json";
import { parseSeasonFromPath } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();

function downloadData() {
    const api_endpoint = configData.SERVER_URL + parseSeasonFromPath(window.location.pathname) + "/datadump"
    return fetch(api_endpoint)
    .then(res => res.blob(),
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {alert(error);})
    .then((res) => {
            console.log(res);
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "datadump.zip");  // 3. Append to html page
            document.body.appendChild(link);  // 4. Force download
            link.click();  // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
    })
    .catch(error => console.warn(error));
}

function DataDumpButton() {
  return (
      <button type="button" onClick={() => downloadData()} className="triggerButton btn btn-dark">Download Data</button>
  );
}

export default DataDumpButton;