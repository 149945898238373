import React from "react";
// import configData from "../config.json";
import { parseSeasonFromPath } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


function rulesS3() {
  return ( 
        <div>
            <div>
              <img alt="tapu fini" src="https://play.pokemonshowdown.com/sprites/ani/tapufini.gif" />
              <img alt="tapu koko" src="https://play.pokemonshowdown.com/sprites/ani/tapukoko.gif" />
              <img alt="tapu bulu" src="https://play.pokemonshowdown.com/sprites/ani/tapubulu.gif" />
              <img alt="tapu lele" src="https://play.pokemonshowdown.com/sprites/ani/tapulele.gif" />
            </div>
            <h1 className="font-weight-light">Season #3 Rules</h1>

            <div id="league-rules">
              <h3>League Rules</h3>
              <ol>
                <li>All matches will follow the SM Doubles OU Banlist and Clauses:<br/>
                  <a href="https://www.smogon.com/dex/sm/formats/doubles/">https://www.smogon.com/dex/sm/formats/doubles/</a>
                </li>
                <li>Division ranking is determined by number of wins, draws, then differential</li>
                <li>Regular Season will schedule 2 matches per week for 8 weeks with 2 byes, for a total of 14 matches per player</li>
                <li>Teams/Pokemon Movesets can change between matches</li>
                <li>Players can only bring Pokémon on their roster to matches</li>
                <li>Each player will play the other 4 in-division players twice in the Regular Season</li>
                <li>Each player will play 4 out of 5 opposing division, in-conference players once in the Regular Season</li>
                <li>Each player will play the 2 matching division-ranked opposing conference players in the last 2 games of the Regular Season</li>
                <li>The last two Regular Season games will be determined based off of division rankings after 8 Regular Season dates (end of Week 4)<a href="#star1">*</a></li>
                <li>Cross conference trades are allowed</li>
                <li>Trades will be allowed up to 1 week after the Free Agency Draft (end of Week 5)</li>
                <li>Any match that ends prematurely (e.g. from a server crash) can be settled in verbal agreement between the players, else a rematch between players (Teams/Pokemon movesets can change before rematch)</li>
              </ol>
            </div>

            <div id="conference-draft-rules">
              <h3>Conference Draft Rules</h3>
              <ol>
                <li>Two Conference Drafts (Rocket and Galactic)</li>
                <li>Conference Draft will be a 12 round snake draft with the order pre-determined by Alex Kisil</li>
                <li>No duplicate draft picks per conference</li>
                <li>Mega stones are treated as items and mega evolutions as effects on the base Pokémon</li>
                <li>Player who drafts Charizard must choose which mega stone (X or Y) they will use when drafting Charizard (the player will not be able to use the other stone)</li>
                <li>Rotom formes are treated as separate Pokémon</li>
              </ol>
            </div>

            <div id="free-agency-draft-rules">
            <h3>Free Agency Draft Rules</h3>
              <ol>
                <li>Two Conference Free Agency Drafts mid-way through Regular Season (end of Week 4, everyone has played 7 or 8 Regular Season games)</li>
                <li>Pokémon drafted must be allowed by the SM Doubles OU Banlist and Clauses: <br/>
                  <a href="https://www.smogon.com/dex/sm/formats/doubles/">https://www.smogon.com/dex/sm/formats/doubles/</a>
                </li>
                <li>Free Agent Drafts will be a 2 round snake draft ordered from lowest to highest conference-ranked player, order will be determined at the middle of Week 4</li>
                <li>Players cannot draft a Pokémon if the Pokémon exists in the Player's Conference's Current Roster</li>
                <li>Players cannot draft a Pokémon if two of it exist in opposing Conference</li>
                <li>Players can only drop a Pokémon that is on their Current Roster or previously picked up in Free Agency</li>
                <li>Pokémon dropped before a player's draft pick can be picked as long as Rules 2 and 3 are met<a href="#star2">**</a></li>
                <li>Players can opt out of 1 or both free agency draft picks</li>
                <li>Trades are banned for the duration of the Free Agency Drafts</li>
              </ol>
            </div>

            <div id="playoff-rules">
              <h3>Playoff Rules</h3>
              <ol>
                <li>Rankings are determined by Wins, Draws, then Differential</li>
                <li>5 players per conference play in the playoffs</li>
                <li>Top 2 players per division make playoffs</li>
                <li>Per conference, the third division-ranked player with the better record gets the wild card spot in the playoffs</li>
                <li>Per conference playoff seeds are assigned after playoff spots are determined and are based off of overall record</li>
                <li>Playoffs are inter-conference, with winners of both conferences facing each other in the finals</li>
              </ol>
            </div>

            <div>
              <p id="star1">* Example: If Deepak is ranked 4th in Rocket West after 7 games at the end of Week 4, he will play the 4th ranked opponent in Galactic East and Galactic West in Week 7</p>
              <p id="star2">** Interesting Edge Case: Prakash and Sid both have Conkeldurr and are in the Rocket Conference, if one of us drops Conkeldurr, a player in Galactic Conference can draft Conkeldurr, if both of us drop Conkeldurr, a player in Rocket Conference can pick Conkeldurr</p>
            </div>


          </div>
  );
}

function rulesS2() {
  return ( 
        <div>    
            <div>
              <img alt="clefable" src="https://play.pokemonshowdown.com/sprites/ani/clefable.gif" />
              <img alt="metagross-mega" src="https://play.pokemonshowdown.com/sprites/ani/metagross-mega.gif" />
              <img alt="landorus-therian" src="https://play.pokemonshowdown.com/sprites/ani/landorus-therian.gif" />
              <img alt="gliscor" src="https://play.pokemonshowdown.com/sprites/ani/gliscor.gif" />
            </div>
            <h1 class="font-weight-light">Season #2 Rules</h1>

            <div id="league-rules">
              <h3>League Rules</h3>
              <ol>
                <li>All matches will follow XY OU Banlist and Clauses:&nbsp;
                  <a href="https://www.smogon.com/dex/xy/formats/ou/">https://www.smogon.com/dex/xy/formats/ou/</a>
                </li>
                <li>Draft will be randomized snake order, 12 rounds (2 teams worth), one draft per conference</li>
                <li>Each pokemon can only be drafted once per conference</li>
                <li>Teams/Pokemon Movesets can change between matches</li>
                <li>Player who drafts Charizard must choose which mega stone (X or Y) they will use on Draft day</li>
                <li>Regular Season will have each player play every one in their conference twice and play each cross-conference player once</li>
                <li>Teams/Pokemon Movesets can change between matches</li>
                <li>Regular season will schedule 2 matches per week per player</li>
                <li>Rank is determined by wins, then differential</li>
                <li>Trades can be made until 3/4 of regular season matches have been played</li>
                <li>Free Agent Draft will be 2 round snake draft ordered from lowest to highest rank player after round 9 and round 18 in the season, one draft per conference</li>
                <li>Any match that ends prematurely (e.g. from a server crash) can be settled in verbal agreement between the players, else a rematch between players. Teams/Pokemon movesets can change before rematch</li>
                <li>Playoffs will be within conference with finals being across conference</li>
                <li>Each playoff matchup will be a best of 3 series, Top 3 ranked players per conference clinch playoff berth, 4th and 5th ranked players will play best of 3 wildcard series</li>
              </ol>
            </div>

            <div id="playoff-rules">
              <h3>Playoff Rules</h3>
              <ol>
                <li>All matches will follow XY OU Banlist and Clauses:&nbsp;
                  <a href="https://www.smogon.com/dex/xy/formats/ou/">https://www.smogon.com/dex/xy/formats/ou/</a>
                </li>
                <li>Each playoff matchup will be a best of 3 series</li>
                <li>Teams/Pokemon Movesets can change between matches in a series</li>
                <li>Any match that ends prematurely (e.g. from a server crash) can be settled in verbal agreement between the players, else a rematch between players. Teams/Pokemon movesets can change before rematch</li>
                <li>Battlers and Spectators must be in separate discord voice channels during playoff games</li>
                <li>Deliberate and insidious attempts to scout opponents teams and movesets before playoff games are prohibited</li>
              </ol>
            </div>

          </div>
  );
}

function rulesS4() {
  return (
    <div>
            <div>
              <img alt="aegislash" src="https://play.pokemonshowdown.com/sprites/ani/aegislash.gif" />
              <img alt="thundurus-therian" src="https://play.pokemonshowdown.com/sprites/ani/thundurus-therian.gif" />
              <img alt="celesteela" src="https://play.pokemonshowdown.com/sprites/ani/celesteela.gif" />
              <img alt="zarude" src="https://play.pokemonshowdown.com/sprites/ani/zarude.gif" />
            </div>
            <h1 className="font-weight-light">Season #4 Rules</h1>

            <div id="league-rules">
              <h3>League Rules</h3>
              <ol>
                <li>All matches will follow the SS Singles UU Play Restrictions as of 1/16/21:<br />
                  <a href="https://www.smogon.com/dex/ss/formats/uu/">https://www.smogon.com/dex/ss/formats/uu/</a></li>
                <li>UU tier valid Pokemon will freeze as of 1/16/21 for the duration of the league, no new Pokemon will be added or banned for duration of the league if implement in UU Play Restrictions.</li>
                <li>Conference ranking is determined by number of wins, draws, then differential</li>
                <li>Regular Season will schedule 2 matches per week for 10 weeks with 4 byes, for a total of 16 matches per player</li>
                <li>Teams/Pokemon movesets can change between matches</li>
                <li>Players can only bring Pokémon on their roster to matches</li>
                <li>Each player will play the other 6 in-conference players twice in the Regular Season</li>
                <li>Each player will play the 2 other matching conference-ranked opposing conference players mid-way through the season, this matchup will be determined after the end of Week 3 (6 rounds of games, before 1st break)</li>
                <li>Each player will play the 2 other matching conference-ranked opposing conference players at the end of the season, this matchup will be determined after the end of Week 7 (14 rounds of games)<a href="#star1">*</a></li>
                <li>Cross conference trades are allowed</li>
                <li>Trades will be allowed up to the end of Week 7 (14 rounds of games)</li>
                <li>Any match that ends prematurely (e.g. from a server crash) can be settled in verbal agreement between the players, else a rematch between players (Teams/Pokemon movesets can change before rematch)</li>
              </ol>
            </div>

            <div id="conference-draft-rules">
              <h3>Conference Draft Rules</h3>
              <ol>
                <li>Three Conference Drafts (Time, Space and Distortion)</li>
                <li>Conference Draft will be a 12 round snake draft with the order pre-determined by Sid, Ali, and Deepak</li>
                <li>No duplicate draft picks per conference</li>
                <li>Rotom formes are treated as separate Pokémon</li>
              </ol>
            </div>

            <div id="free-agency-draft-rules">
            <h3>Free Agency Draft Rules</h3>
              <ol>
                <li>Three Conference Free Agency Drafts mid-way through Regular Season (end of Week 10)</li>
                <li>Pokémon drafted must be allowed by the SS Singles UU Play Restrictions:https://www.smogon.com/dex/ss/formats/uu/</li>
                <li>Free Agent Drafts will be a 2 round snake draft ordered from lowest to highest conference-ranked player, order will be determined at the the point that the Conference Free Agency Draft is held</li>
                <li>Players cannot draft a Pokémon if the Pokémon exists in the Player's Conference's Current Roster</li>
                <li>Players cannot draft a Pokémon if three of it exist in opposing Conferences</li>
                <li>Players can only drop a Pokémon that is on their Current Roster or previously picked up in Free Agency</li>
                <li>Pokémon dropped before a player's draft pick can be picked as long as Rules 2 and 3 are met<a href="#star2">**</a></li>
                <li>Players can opt out of 1 or both free agency draft picks</li>
                <li>Trades are banned for the duration of the Free Agency Drafts (start of the first FA draft to the end of last FA draft)</li>
              </ol>
            </div>

            <div id="playoff-rules">
              <h3>Playoff Rules</h3>
              <ol>
                <li>Rankings are determined by Wins, Draws, then Differential</li>
                <li>Top 2 players per conference (6 total players) make playoffs and are seeded globally in the playoff tournament</li>
                <li>The remaining 3rd-and-lower conference ranked players are ranked globally and the top 4 players enter a play-in tournament for the 7th and 8th seed</li>
                <li>Per conference, the third division-ranked player with the better record gets the wild card spot in the playoffs</li>
                <li>Per conference playoff seeds are assigned after playoff spots are determined and are based off of overall record</li>
                <li>Playoffs are inter-conference, with winners of both conferences facing each other in the finals</li>
              </ol>
            </div>

            <div>
              <p id="star1">* Example: If Deepak is ranked 4th in Rocket West after 7 games at the end of Week 4, he will play the 4th ranked opponent in Galactic East and Galactic West in Week 7</p>
              <p id="star2">** Interesting Edge Case: Prakash and Sid both have Conkeldurr and are in the Rocket Conference, if one of us drops Conkeldurr, a player in Galactic Conference can draft Conkeldurr, if both of us drop Conkeldurr, a player in Rocket Conference can pick Conkeldurr</p>
            </div>


          </div>
  )
}

function rulesS5() {
  return (
    <div>
            <div>
              <img alt="great-tusk" src="https://play.pokemonshowdown.com/sprites/dex/greattusk.png" />
              <img alt="iron-valiant" src="https://play.pokemonshowdown.com/sprites/dex/ironvaliant.png" />
              <img alt="gholdengo" src="https://play.pokemonshowdown.com/sprites/dex/gholdengo.png" />
              <img alt="clodsire" src="https://play.pokemonshowdown.com/sprites/dex/clodsire.png" />
            </div>
            <h1 className="font-weight-light">Season #5 Rules</h1>

            <div id="league-rules">
              <h3>League Rules</h3>
              <ol>
                <li>All matches will follow the SV Singles OU Play Restrictions as of 2/5/23:<br />
                  <a href="https://www.smogon.com/dex/sv/formats/ou/">https://www.smogon.com/dex/sv/formats/ou/</a></li>
                <li>OU tier valid Pokemon will freeze as of 2/5/23 for the duration of the league, no new Pokemon will be added or banned for duration of the league if implemented OU Play Restrictions, including the addition of Pokémon HOME Pokémon.</li>
                <li>Garganacl and Chien-Pao cannot be drafted or picked up in the Free Agency.</li>
                <li>No player can bring a Pokémon with the Illusion Ability to a league match.</li>
                <li>Teams/Pokemon movesets can change between matches</li>
                <li>Conference ranking is determined by number of wins, draws, then differential</li>
                <li>Regular Season will schedule 1 match per week for 8 weeks with 1 bye week.</li>
                <li>Teams/Pokemon movesets can change between matches</li>
                <li>Players can only bring Pokémon on their roster to matches</li>
                <li>Each player will play the 6 of their in-conference players once in the Regular Season</li>
                <li>Each player will play a selected in-conference player, also known as their rival, twice in the Regular Season. </li>
                <li>Cross conference trades are allowed</li>
                <li>Trades will be allowed up to the end of Week 5 (5 rounds of games)</li>
                <li>Any match that ends prematurely (e.g. from a server crash) can be settled in verbal agreement between the players, else a rematch between players (Teams/Pokemon movesets can change before rematch)</li>
              </ol>
            </div>

            <div id="conference-draft-rules">
              <h3>Conference Draft Rules</h3>
              <ol>
                <li>Two Conference Drafts (Great Tusk and Iron Treads)</li>
                <li>Conference Draft will be a 12 round snake draft with the order pre-determined by Sid, Adi, and Prakash</li>
                <li>No duplicate draft picks per conference</li>
                <li>Rotom formes are treated as separate Pokémon</li>
              </ol>
            </div>

            <div id="free-agency-draft-rules">
            <h3>Free Agency Draft Rules</h3>
              <ol>
                <li>Two Conference Free Agency Drafts mid-way through Regular Season (end of Week 5)</li>
                <li>Pokémon drafted must be allowed by the SV Singles OU Play Restrictions as of 2/5/23: https://www.smogon.com/dex/sv/formats/ou/</li>
                <li>Free Agent Drafts will be a 2 round snake draft ordered from lowest to highest conference-ranked player, order will be determined after Week 5 at the point that the Conference Free Agency Draft is held</li>
                <li>Players cannot draft a Pokémon if the Pokémon exists in the Player's Conference's Current Roster</li>
                <li>Players cannot draft a Pokémon if two of it exist in opposing Conference</li>
                <li>Players can only drop a Pokémon that is on their Current Roster or previously picked up in Free Agency</li>
                <li>Pokémon dropped before a player's draft pick can be picked as long as Rules 2 and 3 are met<a href="#star1">*</a></li>
                <li>Players can opt out of 1 or both free agency draft picks</li>
                <li>Trades are banned for the duration of the Free Agency Drafts (start of the first FA draft to the end of last FA draft)</li>
              </ol>
            </div>

            <div id="playoff-rules">
              <h3>Playoff Rules</h3>
              <ol>
                <li>Rankings are determined by Wins, Draws, then Differential</li>
                <li>Top 2 players per conference (4 total players) make playoffs and are seeded globally in the playoff tournament</li>
                <li>The remaining conference ranked players that don't have a playoff spot are ranked globally and the top 4 players enter the playoffs as the 5th through 8th seeds respectively</li>
                <li>Tie-breakers for playoff seeding are either determined by verbally agreement of both tie-breaking players or a single tie-breaking game</li>
                <li>Each playoff game will be a best of 3 game series</li>
              </ol>
            </div>

            <div>
              <p id="star1">** Interesting Edge Case: Julian and Sid both have Volcarona and are in the Great Tusk Conference, if one of us drops Volcarona, a player in Iron Treads Conference can draft Volcarona, if both of us drop Volcarona, a player in Great Tusk Conference can pick Volcarona</p>
            </div>


          </div>
  )
}

function defaultRules(seasonNum) {
  return ( 
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <h1>Wow some text goes here</h1>
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">Rules</h1>
            <p> Season #{ seasonNum } Rules </p>
          </div>
        </div>
      </div>
    </div>
  );
}

class Rules extends React.Component {
  constructor() {
    super();
    this.state = {
      validPokemon: []
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname)
  }

  componentDidMount() {
    const api_endpoint = configData.SERVER_URL + this.seasonNum + "/all_pokemon_map"
    fetch(api_endpoint).then(res => res.json(),
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        alert(error);
      })
    .then(
      results => {
        if (results === undefined) {
          return;
        }
        this.setState({
          validPokemon: Object.keys(results)
        }) 
      },
      (error) => {
      }
    )
    .catch(error => console.warn(error));
  }

  render() {
    // Rules specified in the HTML
    let rulesHTML = "";
    if (this.seasonNum === "3") {
      rulesHTML = rulesS3()
    } else if (this.seasonNum === "2") {
      rulesHTML = rulesS2()
    } else if (this.seasonNum === "4") {
      rulesHTML = rulesS4()
    } else if (this.seasonNum === "5") {
      rulesHTML = rulesS5()
    } else {
      rulesHTML = defaultRules(this.seasonNum)
    }
    // Valid Pokemon
    let validPokemonDiv = ""
    if (this.state.validPokemon.length !== 0) {
      validPokemonDiv = <div id="valid-pokemon">
        <h3>Valid Pokemon</h3>
        <p><i>Below is a complete list of all pokemon available for this season (formes like Keldeo-Resolute or Gastrodon-East notwithstanding).
        Using anything not listed below may cause processing errors.</i> </p>
        {this.state.validPokemon.join(", ")}
        </div>
    }

    return (
    <div className="Rules">
      <div class="container">
      <div class="row align-items-center my-5">
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
        
        {rulesHTML}
        {validPokemonDiv}
      </div>
    </div></div></div>)
  }
}

export default Rules;