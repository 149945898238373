import React from "react";
// import configData from "../config.json";
import "../component_css/TeamAnalyzer.css"

import { parseSeasonFromPath, checkEmptyObject, getRosterInfo, getPokemonIDDetailsInfo, generateTypes } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();
  

function computeSpeed(baseStat, EVs, NatureBoost, StatBoost, IVs=31) {
  const level = 100;

  let value = Math.floor( ((2 * baseStat + IVs + Math.floor(EVs/4))*level) / 100 + 5);
  
  value = Math.floor(NatureBoost * value);
  let StatMod = 1
  if (StatBoost > 0) {
    StatMod += 0.5 * StatBoost;
  } else if (StatBoost < 0) {
    StatMod /= (1 + 0.5 * Math.abs(StatBoost))
  }
  value = Math.floor(value * StatMod)

  return(value)
}

class SpeedTiersPage extends React.Component {
  constructor() {
    super()
    this.state = {
        chosenPlayer: null,
        speedModifier: 0
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.pokemonData = {}
    this.rosterData = {}

    this.setChosenPlayer = this.setChosenPlayer.bind(this)
    this.generatePlayerDropdown = this.generatePlayerDropdown.bind(this)
    this.generateTeamAnalysisTable = this.generateTeamAnalysisTable.bind(this)
    this.seasonConfig = configData.LEAGUE_CONFIG[this.state.seasonNum]
  }

  componentDidMount() {
    getPokemonIDDetailsInfo(this.seasonNum).then(
      results => {this.pokemonData = results;}
    ).then(
      () => {return getRosterInfo(this.seasonNum)}
    ).then(
      results => {
        this.rosterData = results;
        this.setState({
          chosenPlayer: Object.keys(results)[0]
        })
      }
    ).finally(
      // this.setState({})
    )
  }

  setChosenPlayer(newPlayer) {
    this.setState({chosenPlayer: newPlayer})
  }

  setChosenSpeed(newSpeed) {
    this.setState({speedModifier: newSpeed})
  }

  generatePlayerDropdown() {
    const options = []
    Object.keys(this.rosterData).forEach(value => {
        options.push(<option key={value} value={value}>
            {value}
        </option>)
    })
    return <>
        <label>Chosen Player:&nbsp;
        <select name={"gameSelector"}
            value={this.state.chosenPlayer}
            onChange={(e) => this.setChosenPlayer(e.target.value)}>
            {options}
        </select>
    </label> 
    </>
  }

  generateModifierDropdown() {
    const options = []
    for (let i = 6; i >= -6; --i) {
      let iText = i;
      if (i > 0) iText = "+".concat(iText)
      options.push(<option key={i} value={i}>{iText}</option>)
    }
    return <>
        <label>Speed Modifier:&nbsp;
        <select name={"statSelector"}
            value={this.state.speedModifier}
            onChange={(e) => this.setChosenSpeed(e.target.value)}>
            {options}
        </select>
    </label> 
    </>
  }

  generateTeamAnalysisTable() {
    if (checkEmptyObject(this.rosterData)) {
        return <div>Hello World!</div>
    }
    const playerRoster = this.rosterData[this.state.chosenPlayer].roster;
    const pokemonData = this.pokemonData;

    const pokemonList = []
    playerRoster.forEach(function(pokeID) {
      const basePokemonData = JSON.parse(JSON.stringify(pokemonData.base[pokeID]))
      basePokemonData.spriteList = [<img src={basePokemonData.dex_sprite_url} alt="doot" key={pokeID} />]
      if (Object.keys(pokemonData.transf).includes(pokeID)) {
        const transfPokemonData = pokemonData.transf[pokeID]
        const transfPokemonSprite = <img src={transfPokemonData.dex_sprite_url} alt="doot" key={pokeID + "T"} />

        if (basePokemonData.dex_sprite_url === transfPokemonData.dex_sprite_url) {} // Mimikyu, ignore
        else if (basePokemonData.stats.spe === transfPokemonData.stats.spe) { // Same stat so combine on single line
          basePokemonData.spriteList.push(<>&nbsp;/&nbsp;</>)
          basePokemonData.spriteList.push(transfPokemonSprite)
        } else { // Different stat so get their own line
          transfPokemonData.spriteList = [transfPokemonSprite]
          pokemonList.push(transfPokemonData);
        }
      }
      pokemonList.push(basePokemonData);
    })

    pokemonList.sort((a, b) => {
      return b.stats.spe - a.stats.spe
    })

    const tableRows = [];
    const statBoost = this.state.speedModifier;
    // SORT LIST BY POKEMON BASE SPEED THEN NAME
    pokemonList.forEach(function(pokeData) {      
      tableRows.push(
        <tr>
          <td>
            {pokeData.spriteList} <br/>
            {generateTypes(pokeData)}
          </td>
          <td>{pokeData.stats.spe}</td>
          <td>{computeSpeed(pokeData.stats.spe, 0, 0.9, statBoost, 0)}</td>
          <td>{computeSpeed(pokeData.stats.spe, 0, 1, statBoost)}</td>
          <td>{computeSpeed(pokeData.stats.spe, 252, 1, statBoost)}</td>
          <td>{computeSpeed(pokeData.stats.spe, 252, 1.1, statBoost)}</td>
          {/* <td>{computeSpeed(pokeData.stats.spe, 252, 1, true)}</td> */}
          {/* <td>{computeSpeed(pokeData.stats.spe, 252, 1.1, true)}</td> */}
        </tr>
      )
    })

    return <div>
        <h2>Speed Tiers Table</h2>
        <table className="league-table table-responsive table-striped">
        <thead>
        <tr>
            <th>Pokemon</th> <th>Base Stat</th> <th>Min Spe</th> <th>0 Spe</th><th>252 Spe</th> <th>252+ Spe</th>
        </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </table>
    </div>

  }

  render() {
    return (
      <div className="about">
        <div className="container-fluid h-100">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <h1 className="font-weight-light">Season #{ this.seasonNum } Roster Speed Tiers</h1>
              <div>
              {this.generatePlayerDropdown()} &nbsp;
              {this.generateModifierDropdown()}
              </div>
              <div className="analyzer-table-div">
                  <div className="table-container">
                  {this.generateTeamAnalysisTable()}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default SpeedTiersPage;