import configData from "../config.json";
import qpl2Config from "../configs/qpl2Config.json";
import qpl3Config from "../configs/qpl3Config.json";
import qpl3offConfig from "../configs/qpl3OffConfig.json";
import qpl4Config from "../configs/qpl4Config.json";
import qpl5Config from "../configs/qpl5Config.json";
import qpl5offConfig from "../configs/qpl5OffConfig.json";
import qpl6Config from "../configs/qpl6Config.json";
import qpltestConfig from "../configs/qpltestConfig.json"

export function constructLeagueConfigs() {
  let output = configData;
  output["LEAGUE_CONFIG"] = {
    "test": qpltestConfig,
    "2": qpl2Config,
    "3": qpl3Config,
    "3off": qpl3offConfig,
    "4": qpl4Config,
    "5": qpl5Config,
    "5off": qpl5offConfig,
    "6": qpl6Config
  }
  return output
}

export function getSmogonDexName(smogDexUrl) {
  return String(smogDexUrl).replace(
    "https://play.pokemonshowdown.com/sprites/ani/", ""
    ).replace(".gif", "")
}

export function getSmogonDexLink(name, prefix) {
  return "https://www.smogon.com/dex/".concat(prefix, "/pokemon/", name)
}

export function parseSeasonFromPath(path) {
  // TODO: Put this in a helper function
  let temp = String(path)
  if (!temp.endsWith("/")) {
    temp = temp.concat("/")
  }
  let results = temp.split("/");
  let output = null;
  if (results.length > 2) {
    output = results[1];
  } else {
    let last_idx = configData.SEASONS_ORDER.length - 1
    output = configData.SEASONS_ORDER[last_idx]
  }
  return output
}

export function generateTypes(pokeInfo) {
    const types = [];
    for (let typeName of pokeInfo["types"]) {
      types.push(
        <img className="type-img"
          src={configData["TYPE_IMAGES"][typeName]}
          key={typeName}
          alt={"[" + typeName + "]"}></img>
      )
    }
    return types
  }

export function prepareGroupData(dataIn, keyToGroupBy) {
    const output = {
      keysOrdered: new Set(),
      data: {}
    }
  
    for (let playerName in dataIn) {
      const playerData = dataIn[playerName];
      const playerKey = playerData[keyToGroupBy]
      output.keysOrdered.add(playerKey)
      if (playerKey in output.data) {
        console.log("HAHAHAA")
      } else {
        output.data[playerKey] = {}
      }
      output.data[playerKey][playerName] = playerData["roster"]
    }
    output.keysOrdered = Array.from(output.keysOrdered);
    output.keysOrdered.sort()
  
    return output
  }

export function capitalizeFirstLetter(strIn) {
  return strIn.charAt(0).toUpperCase() + strIn.slice(1);
}

export function buildLegend(seasonNum) {
  const cData = configData.LEAGUE_CONFIG[seasonNum]["LEAGUE_TABLE"]["CONFERENCE"];
  const maxRowSize = configData.LEAGUE_CONFIG[seasonNum]["LEAGUE_TABLE"]["LEGEND"]["maxCols"];

  let legendRows = []
  let tempRow = []
  for (let value of Object.values(cData)) {
    if (tempRow.length >= maxRowSize) {
      legendRows.push(<tr>{tempRow}</tr>);
      tempRow = [];
    }

    tempRow.push(
      <td style={value}>{value["displayName"]}</td>
    )
  }
  if (tempRow) {
    legendRows.push(<tr>{tempRow}</tr>);
  }

  return <table className="league-table league-table-legend">
    <thead>
      <tr><th colSpan={maxRowSize}>Legend</th></tr>
    </thead>
    <tbody>
      {legendRows}
    </tbody>
  </table>

}

export function groupPlayersConference(listData) {
  const output = {}
  listData.forEach((datum) => {
    if (!Object.keys(output).includes(datum["player_conference"])) {
      output[datum["player_conference"]] = []
    }
    output[datum["player_conference"]].push(datum["player_name"])
  });

  return output
}

export function checkEmptyObject(obj) {
  let result = true;
  result = result && (obj !== undefined);
  result = result && (obj !== null);
  result = result && (Object.keys(obj).length !== 0);
  return !result
}

export function isNumber(input) {
  const re = /^[0-9\b]*$/;
  return re.test(input) || !input;
}

export function getPlayerAccounts(seasonNum) {
  const api_endpoint = configData.SERVER_URL + seasonNum + "/usernames"
  return fetch(api_endpoint)
    .then(res => res.json(),
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {alert(error);})
}

export function buildAccountString(playerAccounts, player_name) {
  let player_names = ["N/A"]
  if (Object.keys(playerAccounts).includes(player_name)) {
    player_names = playerAccounts[player_name]
  }
  return "Accounts: ".concat(player_names.join(", "))
}

export function computeWeaknessModifier(pokeTypes, typeName) {
  let pokeWeaknessMultiplier = 1;
  pokeTypes.forEach(pokeType => {
    if (configData.TYPE_WEAKNESSES[pokeType].includes(typeName)) {
        pokeWeaknessMultiplier *= 2
    } else if (configData.TYPE_RESISTANCES[pokeType].includes(typeName)) {
        pokeWeaknessMultiplier *= 0.5
    } else if (configData.TYPE_IMMUNITIES[pokeType].includes(typeName)) {
        pokeWeaknessMultiplier = 0
    }
  })
  return pokeWeaknessMultiplier
}


export function fetchAPIEndpoint(seasonNum, endpoint) {
  const api_endpoint = configData.SERVER_URL + seasonNum + endpoint
  return fetch(api_endpoint)
  .then(res => res.json(),
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    (error) => {
      alert(error);
    })
  .then(
    results => {
      if (results === undefined) {
        return;
      }
      return results
    },
    (error) => {
    }
  )
  .catch(error => console.warn(error));
}

export function lookupPlayerConference(playerConfInfo, playerName) {
  for (let [key, value] of Object.entries(playerConfInfo)) {
    if (value.includes(playerName)) {
      return key;
    }
  }
  return undefined;
}

export function generateListHelper(arr) {
  const output = []
  arr.forEach(name => {
    output.push(
      <span id={name}>{name} <br/></span>
    )
  })
  if (output.length === 0) {
    output.push(<span id="doot">--</span>)
  }
  return output
}



export function getPokemonDetailsInfoHelper(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/all_pokemon_map")
  }

  export function getPokemonIDDetailsInfo(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/all_pokemon_id_map")
  }

  export function getFreeAgentInformation(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/free_agents")
  }

  export function getGameDataInfo(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/gamedata")
  }

  export function getRosterInfo(seasonNum, suffix) {
    if (suffix === undefined) {
      suffix = ""
    }
    return fetchAPIEndpoint(seasonNum, "/roster" + suffix)
  }

  export function getDraftOrderRosterInfo(seasonNum, suffix) {
    return fetchAPIEndpoint(seasonNum, "/roster_draft_order" + suffix)
  }

  export function getUsageStats(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/usage_stats")
  }

  export function getPlayerConferenceInfo(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/player_conference")
  }

  export function getTradeLog(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/tradelog")
  }

  export function getReverseLookupInfo(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/reverse_roster")
  }

  export function getGameData(seasonNum, suffix) {
    return fetchAPIEndpoint(seasonNum, "/gamedata?gametype=" + suffix)
  }

  export function getDraftData(seasonNum, suffix) {
    return fetchAPIEndpoint(seasonNum, "/draft" + suffix)
  }

  export function getBattleStatsData(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/battle_stats")
  }

  export function getPlayerUserNames(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/user_info")
  }

  export function getPairwiseUsageInfo(seasonNum) {
    return fetchAPIEndpoint(seasonNum, "/pairwise_usage")
  }