import BaseRosterDraftPage from "./BaseRosterDraftPage";
import '../component_css/Draft.css';

import { buildAccountString, getDraftData } from './helpers';

class Draft extends BaseRosterDraftPage {
  constructor() {
    super();
    this.pageData = {
      order: [],
      data: [],
      conference: null
    }
    this.state.landingPageText = "draft"
  }

  getTableData() {
    return getDraftData(this.seasonNum, window.location.search)
  }

  generateMainBodyTable() {
    const tableHeader = [<th>Round #</th>]
    const tableRows = []

    // Build the table Header
    const playerAccounts = this.playerAccounts;
    this.pageData.order.forEach((playerName) => {
      tableHeader.push(<th title={buildAccountString(playerAccounts, playerName)}>{playerName}</th>)
    })

    // Build the table rows
    this.pageData.data.forEach((rowData, idx) => {
      const tableRow = [<td><span className="draft-number">{idx + 1}</span></td>]
      rowData.forEach((datum) => {
        const pokemonKey = "".concat(datum["player_name"]).concat("|").concat(datum["pokemon_id"]);
        console.log(datum)
        tableRow.push(<td>{this.generatePokemonIconCell(pokemonKey, datum["pokemon_id"])}</td>)
      });
      tableRows.push(<tr>{tableRow}</tr>)
    })

    const fullTable = 
    <table className="table-responsive table-striped roster-table">
      <thead>
        <tr>
          {tableHeader}
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>

    // Generate the header
    let header = <div></div>
    if (this.pageData.conference !== null) {
      header = <h1 className="font-weight-light">{this.seasonConfig.ROSTER.CONFERENCE[this.pageData.conference].displayName} Draft</h1>
    }

    return <div className={"row align-items-center h-100 roster-division-column" + (this.state.australiaView ? " australia-view" : "")}>
      <div className="col-md-1"></div>
      <div className="col-md-10">
        {header}
        {fullTable}
      </div>
    </div>
  }

}

export default Draft;