// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-pw-div {
    display: table;
    margin: auto;
    margin-bottom: 15px;
}

.wipe-table-toggle {
    display: table;
    margin: auto;
    padding-bottom: 10px;
}

img.loading-img {
    display: block;
    margin: auto
}

.gameOverrideRow {
    margin-top: 15px;
}

.overrideRowMisc {
    margin-top: 5px;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/component_css/Admin.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".admin-pw-div {\n    display: table;\n    margin: auto;\n    margin-bottom: 15px;\n}\n\n.wipe-table-toggle {\n    display: table;\n    margin: auto;\n    padding-bottom: 10px;\n}\n\nimg.loading-img {\n    display: block;\n    margin: auto\n}\n\n.gameOverrideRow {\n    margin-top: 15px;\n}\n\n.overrideRowMisc {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
