import React from "react";
// import configData from "../config.json";
import '../component_css/TradeLog.css';

import { parseSeasonFromPath, buildLegend, getTradeLog } from './helpers';
import PokemonDetailsPage from './PokemonDetailsPage';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();

class TradeLog extends PokemonDetailsPage {
  constructor() {
    super()
    this.seasonNum = parseSeasonFromPath(window.location.pathname)
    this.state = {
      activePokemon: [],
      tradeToggle: true,
      iconView: false,
      chosenPlayer: "",
      chosenPokemon: ""
    }
    this.data = {
      fa: [],
      trade: []
    }
    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum]
    this.trigger_fa_toggle = this.trigger_fa_toggle.bind(this)
    this.updateActive = this.updateActive.bind(this);
  }

  trigger_fa_toggle() {
    const oldState = this.state;
    oldState.tradeToggle = !oldState.tradeToggle;
    oldState.activePokemon = [];
    this.setState(oldState)
  }

  componentDidMount() {
    this.getPokemonInfo().then(value => {
      this.pokemonInfo = value
    }).then(() => {
      return getTradeLog(this.seasonNum);
    }).then(
      (results) => {
        this.data = results;
      }
    ).finally(
      // Force re-render
      () => {this.setState(this.state)}
    )
  }

  generatePlayerCell(datum, name_key, conf_key) {
    // Player Cell
    let style1 = {};
    if (Object.keys(configData.LEAGUE_CONFIG).indexOf(this.seasonNum) !== -1) {
      const confStyles = this.seasonConfig["LEAGUE_TABLE"]["CONFERENCE"];
      if (datum[conf_key] in confStyles) {
        style1 = confStyles[datum[conf_key]];
      }
    }
    return <td style={style1}>{datum[name_key]}</td>;
  }

  buildTable() {
    // Build Table header
    const tableHeader = [];
    tableHeader.push(<th>Date</th>)
    if (this.state.tradeToggle) {
      tableHeader.push(<th>Player 1</th>)
      tableHeader.push(<th>Player 1 Gained</th>)
      tableHeader.push(<th>Player 2</th>)
      tableHeader.push(<th>Player 2 Gained</th>)
    } else {
      tableHeader.push(<th>Player</th>)
      tableHeader.push(<th>Pok&eacute;mon Gained</th>)
      tableHeader.push(<th>Pok&eacute;mon Dropped</th>)
    }

    const tableRows = []
    const data = this.state.tradeToggle ?  this.data.trade : this.data.fa;
    data.forEach((datum) => {
      // Filter based on player
      if (this.state.chosenPlayer !== "" && ![datum.player1, datum.player2].includes(this.state.chosenPlayer)) {
        return
      }
      if (this.state.chosenPokemon !== "" && ![datum.pokemon_gained_id, datum.pokemon_lost_id].includes(this.state.chosenPokemon)) {
        return
      }

      const temp = [];
      temp.push(<td>{datum.date}</td>)

      // Player Cell
      temp.push(this.generatePlayerCell(datum, "player1", "player1_conference"));

      // Pokemon Gained Cell
      const pokemonGainedKey = datum.player1 + datum.pokemon_gained_id + datum.pokemon_lost_id;
      if (this.state.iconView) {
        temp.push(<td>{this.generatePokemonIconCell(pokemonGainedKey, datum.pokemon_gained_id)}</td>)
      } else {
        temp.push(<td>{this.generatePokemonSpriteCell(pokemonGainedKey, datum.pokemon_gained_id)}</td>)
      }


      // Pokemon Lost Cell
      var pokemonLostKey = datum.player1 + datum.pokemon_lost_id + datum.pokemon_gained_id;
      if (this.state.tradeToggle === true) {
        temp.push(this.generatePlayerCell(datum, "player2", "player2_conference"));
        pokemonLostKey = datum.player2 + datum.pokemon_lost_id + datum.pokemon_gained_id;
      }
      if (datum.pokemon_lost_id !== null & datum.pokemon_lost_id !== undefined) {
        if (this.state.iconView) {
          temp.push(<td>{this.generatePokemonIconCell(pokemonLostKey, datum.pokemon_lost_id)}</td>)
        } else {
          temp.push(<td>{this.generatePokemonSpriteCell(pokemonLostKey, datum.pokemon_lost_id)}</td>)
        }
      } else {
        temp.push(<td>---</td>)
      }

      // We done
      tableRows.push(<tr>{temp}</tr>);
    });

    const output = <table className="league-table table-responsive table-striped">
      <thead>
        <tr>
          {tableHeader}
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
    return output
  }

  buildSearchBars() {
    const data = this.state.tradeToggle ?  this.data.trade : this.data.fa;
    
    const playerChoices = []
    const pokeChoices = []
    playerChoices.push("")
    pokeChoices.push("")

    data.forEach(datum => {
      if (!playerChoices.includes(datum.player1) && datum.player1) {
        playerChoices.push(datum.player1)
      }
      if (!playerChoices.includes(datum.player2) && datum.player2) {
        playerChoices.push(datum.player2)
      }

      if (!pokeChoices.includes(datum.pokemon_gained_id)) {
        pokeChoices.push(datum.pokemon_gained_id)
      }
      if (!pokeChoices.includes(datum.pokemon_lost_id)) {
        pokeChoices.push(datum.pokemon_lost_id)
      }
    })

    playerChoices.sort() // Make iteration order alphabetical
    const playerListOpts = []
    playerChoices.forEach(val => {
      playerListOpts.push(<option value={val}>{val}</option>)
    })

    pokeChoices.sort()
    const pokeListOpts = []
    pokeChoices.forEach(val => {
      if (this.pokemonInfo.base === undefined) {
        return
      } else if (val === "") {
        pokeListOpts.push(<option value={val}>{val}</option>)
        return
      } else if (val === null | val === undefined) {
        return
      }

      const chosenPokeInfo = this.pokemonInfo.base[val];
      pokeListOpts.push(<option value={val}>{chosenPokeInfo.pokemon_name}</option>)
    })
    
    return <div className="container-fluid">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
        <label className="gut-label"><b>Player:</b> &nbsp;
          <select value={this.state.chosenPlayer}
            onChange={(e) => this.setState({chosenPlayer: e.target.value})}>{playerListOpts}</select>
          </label> &nbsp;&nbsp;
          <label className="gut-label"><b>Pok&eacute;mon:</b> &nbsp;
          <select value={this.state.chosenPokemon}
            onChange={(e) => this.setState({chosenPokemon: e.target.value})}>{pokeListOpts}</select>
          </label>

        </div>
      </div>
    </div>
  }

  render() {
    const faButton = <button type="button" onClick={() => this.trigger_fa_toggle()} className="triggerButton btn btn-dark">
      View {this.state.tradeToggle ? "Free Agency" : "Trades"}
    </button>

    const body = <div className="trade-log-table-div">
      {buildLegend(this.seasonNum)}
      {this.buildSearchBars()}
      <div className="table-container">
        {this.buildTable()}
      </div>
    </div>

    return (
      <div className="about">
        <div className="container-fluid">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <h1 className="font-weight-light">Season #{ this.seasonNum } {this.state.tradeToggle ? "Trades" : "Free Agency Picks"}</h1>
              <div className="row align-items-center button-bar">
                {faButton} {this.generateDetailsButton()} {this.generateIconButton()}
              </div>
      
              {body}
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default TradeLog;