// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-details-roster-container {
    text-align: center;
}

.game-details-roster-img {
    display: inline-block;
    margin: auto;
    padding: 4px;
}

.game-summary-block {
    text-align: left;
    display: inline-block;
    margin: auto;
}

.selected-pokemon {
    border: 2px dashed rgba(200, 200, 200, 0.5);
    padding: 2px
}

.empty-field {
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/component_css/GameDetailsView.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,2CAA2C;IAC3C;AACJ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".game-details-roster-container {\n    text-align: center;\n}\n\n.game-details-roster-img {\n    display: inline-block;\n    margin: auto;\n    padding: 4px;\n}\n\n.game-summary-block {\n    text-align: left;\n    display: inline-block;\n    margin: auto;\n}\n\n.selected-pokemon {\n    border: 2px dashed rgba(200, 200, 200, 0.5);\n    padding: 2px\n}\n\n.empty-field {\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
