// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.trade-main-pokemon{
    webkit-transform: scale(1.0); /* Saf3.1+, Chrome */ /* FF3.5+ */ /* IE9 */ /* Opera 10.5+ */
     transform: scale(1.0);
    margin-top: 5px;
    margin-bottom: 5px;
}

.trade-cell img {
    vertical-align: text-bottom;
}

.trade-mini-pokemon {
    display: inline-block;
}


button.btn-dark {
    background-color: #343a40 !important;
    border-color: rgba(200,200,200,0.1);
  }
  
button.btn-dark:hover {
    background-color: #212529;
    border-color: rgba(200,200,200,0.2);
}

.trade-log-table-div th {
    font-size: 18pt;
}

.trade-log-table-div td {
    font-size: 14pt;
}

.trade-log-table-div .tradelog-cell {
    font-size: 12pt;
}

.trade-log-table-div .active-cell {
    font-size: 12pt;
}


.trade-log-table-div .pokemon-stats {
    font-size: 10pt;
}

.button-bar {
    width: 60%;
}`, "",{"version":3,"sources":["webpack://./src/component_css/TradeLog.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B,EAAE,oBAAoB,EACrB,WAAW,EACX,QAAQ,EACR,gBAAgB;KACvC,qBAAqB;IAC3B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,oCAAoC;IACpC,mCAAmC;EACrC;;AAEF;IACI,yBAAyB;IACzB,mCAAmC;AACvC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":["img.trade-main-pokemon{\n    webkit-transform: scale(1.0); /* Saf3.1+, Chrome */\n     -moz-transform: scale(1.0); /* FF3.5+ */\n      -ms-transform: scale(1.0); /* IE9 */\n       -o-transform: scale(1.0); /* Opera 10.5+ */\n          transform: scale(1.0);\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.trade-cell img {\n    vertical-align: text-bottom;\n}\n\n.trade-mini-pokemon {\n    display: inline-block;\n}\n\n\nbutton.btn-dark {\n    background-color: #343a40 !important;\n    border-color: rgba(200,200,200,0.1);\n  }\n  \nbutton.btn-dark:hover {\n    background-color: #212529;\n    border-color: rgba(200,200,200,0.2);\n}\n\n.trade-log-table-div th {\n    font-size: 18pt;\n}\n\n.trade-log-table-div td {\n    font-size: 14pt;\n}\n\n.trade-log-table-div .tradelog-cell {\n    font-size: 12pt;\n}\n\n.trade-log-table-div .active-cell {\n    font-size: 12pt;\n}\n\n\n.trade-log-table-div .pokemon-stats {\n    font-size: 10pt;\n}\n\n.button-bar {\n    width: 60%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
