import React from "react";
// import configData from "../config.json";
import PokemonDetailsPage from "./PokemonDetailsPage"
import { getFreeAgentInformation, parseSeasonFromPath, capitalizeFirstLetter, computeWeaknessModifier, generateListHelper } from "./helpers";

import "../component_css/BattleStats.css"
import "../component_css/UsageStats.css"
import "../component_css/FreeAgents.css"

import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


class FreeAgentsPage extends PokemonDetailsPage {
  constructor() {
    super();
    this.state = {
      ...this.state,
      activePokemon: [],
      iconView: true,
      detailsView: true,
      searchPokemon: "",
      searchAbility: "",
      searchConference: "",
      moveFilters: {
        boostMoves: false,
        healingMoves: false,
        priorityMoves: false,
        hazardMoves: false,
        pivotMoves: false,
        statusMoves: false,
      },
      hideColumns: {
        boostMoves: false,
        healingMoves: false,
        priorityMoves: false,
        hazardMoves: false,
        pivotMoves: false,
        statusMoves: false,
      },
      hideNFEs: true,
      searchType: "",
      resistType: "",
      excludeWeakType: "",
      statFilterKey: "",
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum];
    this.pageTitle = <h1 className="font-weight-light">Season #{ this.seasonNum } Available Free Agents</h1>
    this.tableColumns = [
      {
        header: "Boosting Moves",
        datumKey: "self_stat_boost",
        filterKey: "boostMoves"
      },
      {
        header: "Healing Moves",
        datumKey: "heal",
        filterKey: "healingMoves"
      },
      {
        header: "Priority Attack Moves",
        datumKey: "priority_attack",
        filterKey: "priorityMoves"
      },
      {
        header: "Hazard Moves",
        datumKey: "hazards",
        filterKey: "hazardMoves"
      },
      {
        header: "Pivoting Moves",
        datumKey: "pivot",
        filterKey: "pivotMoves"
      },
    ]

    this.buildTableHTML = this.buildTableHTML.bind(this);
    this.checkKeyMatchesFilterCriteria = this.checkKeyMatchesFilterCriteria.bind(this);
    this.generateNFEFilterButton = this.generateNFEFilterButton.bind(this);
    this.triggerNFEFilter = this.triggerNFEFilter.bind(this);
    this.updateSearchPokemon = this.updateSearchPokemon.bind(this);
    this.updateSearchAbility = this.updateSearchAbility.bind(this);
    this.updateResistType = this.updateResistType.bind(this);
    this.updateSearchConference = this.updateSearchConference.bind(this);
    this.generateTypeFilterDropdowns = this.generateTypeFilterDropdowns.bind(this);
    this.generateTypeFilterFields = this.generateTypeFilterFields.bind(this);
    this.updateSearchType = this.updateSearchType.bind(this);
    this.triggerMoveTypeFilter = this.triggerMoveTypeFilter.bind(this);
    this.triggerColumnHide = this.triggerColumnHide.bind(this);
    this.generateMoveFilterButtons = this.generateMoveFilterButtons.bind(this);
    this.buildFAInfoColumns = this.buildFAInfoColumns.bind(this);
    this.buildTableHeaderCols = this.buildTableHeaderCols.bind(this)

    this.freeAgentInfo = {}
    this.displayTierInfo = true;
  }

  componentDidMount() {
    this.getPokemonInfo().then(
      (results) => {
        this.pokemonInfo = results
      }
    ).then(
      () => {
        return getFreeAgentInformation(this.seasonNum)
      }
    ).then(
      (results) => {
        this.freeAgentInfo = results;
      }
    ).finally(
      () => {
        this.setState(this.state)
      }
    )
  }

  triggerStatFilter(statKey) {
    if (this.state.statFilterKey === statKey) {
      this.setState({statFilterKey: ""})
    } else {
      this.setState({statFilterKey: statKey})
    }
  }

  updateSearchPokemon(event) {
    this.setState({searchPokemon: event.target.value})
  } 

  updateSearchAbility(event) {
    this.setState({searchAbility: event.target.value})
  }

  updateResistType(event) {
    this.setState({resistType: event.target.value});
  }

  updateSearchType(event) {
    this.setState({searchType: event.target.value});
  }

  triggerMoveTypeFilter(stateKey) {
    const newMoveFilters = this.state.moveFilters
    newMoveFilters[stateKey] = !this.state.moveFilters[stateKey]
    this.setState({moveFilters: newMoveFilters})
  }

  triggerColumnHide(stateKey) {
    const newHideColumns = this.state.hideColumns;
    newHideColumns[stateKey] = !this.state.hideColumns[stateKey];
    this.setState({hideColumns: newHideColumns});
  }

  triggerNFEFilter() {
    this.setState({hideNFEs: !this.state.hideNFEs});
  }

  updateExcludeWeaknessType(event) {
    this.setState({excludeWeakType: event.target.value});
  }

  updateSearchConference(event) {
    this.setState({searchConference: event.target.value});
  }

  generateTypeFilterDropdowns() {
    const typeOptions = [];
    Object.keys(configData.TYPE_RESISTANCES).forEach(val => {
      typeOptions.push(<option value={val}>{capitalizeFirstLetter(val)}</option>)
    })
    return <div className="btn-toolbar sort-button-bar">
        <div className="btn-group">
        <label className="gut-label">Type Search:</label> &nbsp;
          <select value={this.state.searchType} onChange={(e) => this.updateSearchType(e)}>
            <option value=""></option>
            {typeOptions}
          </select> &nbsp;&nbsp;
        <label className="gut-label">Type Resist:</label> &nbsp;
          <select value={this.state.resistType} onChange={(e) => this.updateResistType(e)}>
            <option value=""></option>
            {typeOptions}
          </select> &nbsp;&nbsp;
          <label className="gut-label">Exclude Weakness:</label> &nbsp;
          <select value={this.state.excludeWeakType} onChange={(e) => this.updateExcludeWeaknessType(e)}>
            <option value=""></option>
            {typeOptions}
          </select>
        </div>
      </div>
  }

  generateTypeFilterFields(excludeSearchPokemon=false, excludeSearchAbility=false, excludeSearchConference=false) {
    return <div className="btn-toolbar sort-button-bar">
    <div className="btn-group">
      { excludeSearchPokemon ?
        <span></span> :
        <div><label className="gut-label">Pok&eacute;mon Search:</label> &nbsp;
        <input type="text" value={this.state.searchPokemon} onChange={this.updateSearchPokemon} style={{width: "150px"}} /> &nbsp;&nbsp;
        </div>
      }
      { excludeSearchAbility ?
        <span></span> :
        <div>
          <label className="gut-label">Ability Search:</label> &nbsp;
          <input type="text" value={this.state.searchAbility} onChange={this.updateSearchAbility} style={{width: "150px"}} /> &nbsp;&nbsp;
        </div>
      }
      {
        excludeSearchConference ? 
        <span></span> :
        <div>
          <label className="gut-label">Conference Search:</label> &nbsp;
          <input type="text" value={this.state.searchConference} onChange={this.updateSearchConference} style={{width: "150px"}} /> &nbsp;&nbsp;
        </div>
      }
    </div> </div>
  }

  generateNFEFilterButton() {
    return <button onClick={this.triggerNFEFilter} className="triggerButton btn btn-dark">
        {(this.state.hideNFEs ? "Show" : "Hide") + " NFEs"}
    </button>
  }

  generateMoveFilterButtons(func, activeObj, activeClass, label) {
    return <div className="btn-toolbar sort-button-bar">
      <div className="btn-group" role="group">
      <div class="input-group-prepend">
        <div class="input-group-text">{label}</div>
      </div>
      <button onClick={() => {func("boostMoves")}} className={"triggerButton btn " + (activeObj.boostMoves ? activeClass : "btn-dark") }>
        Boosting Moves
      </button>
      <button onClick={() => {func("healingMoves")}} className={"triggerButton btn " + (activeObj.healingMoves ? activeClass : "btn-dark") }>
        Healing Moves
      </button>
      <button onClick={() => {func("priorityMoves")}} className={"triggerButton btn " + (activeObj.priorityMoves ? activeClass : "btn-dark") }>
        Prioirty Moves
      </button>
      <button onClick={() => {func("hazardMoves")}} className={"triggerButton btn " + (activeObj.hazardMoves ? activeClass : "btn-dark") }>
        Hazard Moves
      </button>
      <button onClick={() => {func("pivotMoves")}} className={"triggerButton btn " + (activeObj.pivotMoves ? activeClass : "btn-dark") }>
        Pivot Moves
      </button>
      </div>
    </div>
  }

  generateStatSortingButtons() {
    return <div className="btn-toolbar sort-button-bar">
      <div className="btn-group" role="group">
      <button onClick={() => {this.triggerStatFilter("hp")}} className={"triggerButton btn " + (this.state.statFilterKey === "hp" ? "btn-primary" : "btn-dark") }>
        HP
      </button>
      <button onClick={() => {this.triggerStatFilter("atk")}} className={"triggerButton btn " + (this.state.statFilterKey === "atk" ? "btn-primary" : "btn-dark") }>
        Attack
      </button>
      <button onClick={() => {this.triggerStatFilter("def")}} className={"triggerButton btn " + (this.state.statFilterKey === "def" ? "btn-primary" : "btn-dark") }>
        Defense
      </button>
      <button onClick={() => {this.triggerStatFilter("spa")}} className={"triggerButton btn " + (this.state.statFilterKey === "spa" ? "btn-primary" : "btn-dark") }>
        Special Attack
      </button>
      <button onClick={() => {this.triggerStatFilter("spd")}} className={"triggerButton btn " + (this.state.statFilterKey === "spd" ? "btn-primary" : "btn-dark") }>
        Special Defense
      </button>
      <button onClick={() => {this.triggerStatFilter("spe")}} className={"triggerButton btn " + (this.state.statFilterKey === "spe" ? "btn-primary" : "btn-dark") }>
        Speed
      </button>
      </div>
    </div>
  }

  checkKeyMatchesFilterCriteria(key) {
    // const faConferences = this.freeAgentInfo[key]
    let faDetails = this.pokemonInfo["base"][key]
    if (this.state.activePokemon.includes(key)) {
      console.log("--> Transf: " + key)
      faDetails = this.pokemonInfo["transf"][key]
    }

    // Pokemon meets NFE criteria
    if (this.state.hideNFEs & faDetails["fully_evolved_flag"] === 0) {
      // Not fully evolved and we want to hide these
      return false
    }

    // Pokemon doesn't match one of the move filters
    if (this.state.moveFilters.pivotMoves & faDetails["learnset"]["pivot"].length === 0) {
      return false
    } 
    if (this.state.moveFilters.healingMoves & faDetails["learnset"]["heal"].length === 0) {
      return false
    } 
    if (this.state.moveFilters.priorityMoves & faDetails["learnset"]["priority_attack"].length === 0) {
      return false
    } 
    if (this.state.moveFilters.hazardMoves & faDetails["learnset"]["hazards"].length === 0) {
      return false
    } 
    if (this.state.moveFilters.boostMoves & faDetails["learnset"]["self_stat_boost"].length === 0) {
      return false
    } 

    // Pokemon meets resistance criteria
    if (this.state.resistType === "") {/* No Filtering */}
    else if (computeWeaknessModifier(faDetails.types, this.state.resistType) >= 1) {
      // If resist, then modifier < 1
      return false
    }

    // Pokemon may fail to meet not-weakness criteria
    if (this.state.excludeWeakType === "") {/* No Filtering */}
    else if (computeWeaknessModifier(faDetails.types, this.state.excludeWeakType) > 1) {
      return false
    }

    if (this.state.searchType === "") {/* No Filtering */}
    else if (!faDetails.types.includes(this.state.searchType)) {
      return false
    }

    // Pokemon name matches
    if (this.state.searchPokemon === "") {/* No filtering */} 
    else if( faDetails["pokemon_name"].toLowerCase() === this.state.searchPokemon.toLowerCase() |
      faDetails["pokemon_name"].toLowerCase().includes(this.state.searchPokemon.toLowerCase())) { /* Filtering matches */}
    else {
      return false
    }

    // Check if pokemon has matching ability
    let hasMatchingAbility = false;
    if (this.state.searchAbility === "") {
      /* No filtering */
      hasMatchingAbility = true;
    } else {
      for (let ability of faDetails["abilities"]) {
        if (ability === null | ability === undefined) {/* Just skip invalid abilities */}
        else if (ability.toLowerCase() === this.state.searchAbility.toLowerCase() |
          ability.toLowerCase().includes(this.state.searchAbility.toLowerCase())) {
            hasMatchingAbility = true;
            break;
        }
      } 
    }
    if (hasMatchingAbility === false) {
      return false
    }
    return true
  }

  buildFAInfoColumns(key, faDetails) {
    const rowCols = [<td>{this.state.iconView ? 
        this.generatePokemonIconCell(key, key) : 
        this.generatePokemonSpriteCell(key, key)}
      </td>];
    this.tableColumns.forEach(obj => {
      if (this.state.hideColumns[obj.filterKey] === false) {
        rowCols.push(<td className="move-list">
          {generateListHelper(faDetails["learnset"][obj.datumKey])}
        </td>)
      }
    })
    return rowCols;
  }

  buildTableHeaderCols() {
    const headerRow = [<th>Pok&eacute;mon</th>];
    this.tableColumns.forEach(obj => {
      if (this.state.hideColumns[obj.filterKey] === false) {
        headerRow.push(<th>{obj.header}</th>)
      }
    })
    return(headerRow)
  }

  buildTableHTML() {
    const tableRows = []
    let faKeys = Object.keys(this.freeAgentInfo);

    // Sort by Speed
    if (this.state.statFilterKey !== "") {
      faKeys = faKeys.sort((a, b) => {
        const aDetails = this.pokemonInfo["base"][a];
        const bDetails = this.pokemonInfo["base"][b];
        return (aDetails.stats[this.state.statFilterKey] > bDetails.stats[this.state.statFilterKey]) ? -1 : 1
      })
    }

    faKeys.forEach(key => {
      if (!this.checkKeyMatchesFilterCriteria(key)) {
        return
      }

      const faConferences = this.freeAgentInfo[key]
      let faDetails = this.pokemonInfo["base"][key]
      if (this.state.activePokemon.includes(key)) {
        faDetails = this.pokemonInfo["transf"][key]
      }

      // Check if pokemon available in conference
      let availableInConference = false
      if (this.state.searchConference === "") {
        /* No Filtering */
        availableInConference = true
      }
      else {
        faConferences.forEach(confName => {
          const temp = confName.toLowerCase().includes(this.state.searchConference.toLowerCase())
          availableInConference = availableInConference | temp;
        })
      }
      if (!availableInConference) {
        return false
      }

      const faConfArray = []
      faConferences.forEach(confID => {
        const confName = this.seasonConfig.ROSTER.CONFERENCE[confID.toUpperCase()].displayName.trim()
        faConfArray.push(<span><a href={"roster?conference=" + confID.toUpperCase()}>{confName}</a> <br/></span>)
      })

      const rowCols = this.buildFAInfoColumns(key, faDetails);
      tableRows.push(
        <tr>
          {rowCols}
          <td>{faConfArray}</td>
        </tr>
      )
    })

    const headerRow = this.buildTableHeaderCols();

    return <div>
          <br/>
          <table className="league-table table-responsive table-striped">
          <thead>
          <tr>
              {headerRow}
              <th>Conference(s) Available</th>
          </tr>
          </thead>
          <tbody>
              {tableRows}
          </tbody>
      </table>
      </div>
  }

  render() {
    return <div className="about">
      <div className="container-fluid">
        <div className="row align-items-center my-5 ">
          <div className="col-sm-1"></div>
          <div className="col-sm-10">

              {this.pageTitle}
              <div className="usage-stats-table-div">
                  <div className="row align-items-center button-bar">
                    {this.generateDetailsButton()}
                    {this.generateIconButton()}
                    {this.generateNFEFilterButton()}
                  </div>
                  <div className="row align-items-center sort-button-bar">
                    {this.generateStatSortingButtons()}
                  </div>
                  <div className="row align-items-center sort-button-bar">
                    {this.generateMoveFilterButtons(this.triggerColumnHide, this.state.hideColumns, "btn-danger", "Column Hide")}
                  </div>
                  <div className="row align-items-center sort-button-bar">
                    {this.generateMoveFilterButtons(this.triggerMoveTypeFilter, this.state.moveFilters, "btn-primary", "Column Filter")}
                  </div>
                  <div className="row align-items-center sort-button-bar">
                    {this.generateTypeFilterFields()}
                    {this.generateTypeFilterDropdowns()}
                  </div>
                  
                  <div className="table-container">
                  {this.buildTableHTML()}
                  </div>
              </div>

          </div>
        </div>
      </div>
    </div>
  }
  }

  export default FreeAgentsPage;