import React from "react";
// import configData from "../config.json";
import "../component_css/UsageStats.css"

import { parseSeasonFromPath, isNumber, groupPlayersConference, getUsageStats, getPlayerConferenceInfo, lookupPlayerConference } from './helpers';
import PokemonDetailsPage from './PokemonDetailsPage';
import DataDumpButton from "./DataDumpButton";
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


class UsageStats extends PokemonDetailsPage {
  constructor() {
    super()
    this.state = {
      thresholdLimit: 0,
      searchPokemon: "",
      activePokemon: [],
      activeConference: undefined,
      activePlayer: undefined,
      sortingKey: "usage_pct",
      sortOrderDesc: true,
      displayNotOnRoster: true,
      iconView: false
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.data = {
      player: {},
      pokemon: []
    };
    this.playerInfo = {};
    this.pokemonInfo = {
      base: {},
      transf: {}
    };

    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum]
    this.updateActiveConference = this.updateActiveConference.bind(this);
    this.updateActivePlayer = this.updateActivePlayer.bind(this);
    this.updateActive = this.updateActive.bind(this);
    this.generateSortingButtons = this.generateSortingButtons.bind(this);
    this.updateSortingVariable = this.updateSortingVariable.bind(this);
    this.updateSortingOrder = this.updateSortingOrder.bind(this);
    this.updateThresholdLimit = this.updateThresholdLimit.bind(this);
    this.updateSearchPokemon = this.updateSearchPokemon.bind(this);
    this.generateCurrentRosterButton = this.generateCurrentRosterButton.bind(this);
  }

  updateActiveConference(newConference) {
    const oldState = this.state;
    if (newConference === oldState.activeConference) {
        oldState.activeConference = undefined
        oldState.activePlayer = undefined
        oldState.displayNotOnRoster = true
    } else {
        oldState.activeConference = newConference;
        oldState.activePlayer = this.playerInfo[newConference][0]
    }
    oldState.activePokemon = []
    this.setState(oldState)
  }

  updateThresholdLimit(event) {
    let newLimit = event.target.value
    if (isNumber(newLimit)) {
      newLimit = parseInt(newLimit);
      if (newLimit >= 0) {
          this.setState({thresholdLimit: newLimit})
      } else {
          this.setState({thresholdLimit: 0})
      }
    }
  }

  updateSearchPokemon(event) {
    this.setState({searchPokemon: event.target.value})
  }

  updateActivePlayer(newPlayer) {
    const oldState = this.state;
    if (newPlayer === oldState.activePlayer) {
        return
    } else {
        oldState.activePlayer = newPlayer;
    }
    oldState.activeConference = lookupPlayerConference(this.playerInfo, newPlayer)
    oldState.activePokemon = []
    this.setState(oldState)
  }

  componentDidMount() {
    getUsageStats(this.seasonNum).then(
      (results) => {
        this.data = results;
      }).then(
        () => {
          return getPlayerConferenceInfo(this.seasonNum);
        }).then(
          (results) => {
            this.playerInfo = groupPlayersConference(results);
          }
        ).then(
          () => {
            return this.getPokemonInfo()
          }
        ).then(
          (results) => {
            this.pokemonInfo = results;
          }
        )
        .finally(
          // Force a re-render
          () => {
            this.setState(this.state)
          }
        )
  }

  buildClickableLegend(seasonNum) {
    const cData = this.seasonConfig["LEAGUE_TABLE"]["CONFERENCE"];
    const maxRowSize = this.seasonConfig["LEAGUE_TABLE"]["LEGEND"]["maxCols"];
  
    let legendRows = []
    let tempRow = []
    for (let [key, value] of Object.entries(cData)) {
      if (tempRow.length >= maxRowSize) {
        legendRows.push(<tr>{tempRow}</tr>);
        tempRow = [];
      }

        let thisClassName = ""
        if (this.state.activeConference === key) {
        thisClassName = "active-conference"
        }

        tempRow.push(
        <td className={thisClassName} onClick={() => {this.updateActiveConference(key)}} style={value}>{value["displayName"]}</td>
    )
    }
    if (tempRow) {
      legendRows.push(<tr>{tempRow}</tr>);
    }

    // Now add the second row for the players
    const playerRow = []
    if (this.state.activeConference !== undefined) {
        const players = this.playerInfo[this.state.activeConference]
        const style = this.seasonConfig["LEAGUE_TABLE"]["CONFERENCE"][this.state.activeConference]
        players.forEach((datum) => {
            let thisClassName = "";
            if (this.state.activePlayer === datum) {
                thisClassName = "active-conference"
                }
            playerRow.push(<td 
                className={thisClassName}
                onClick={() => {this.updateActivePlayer(datum)}}
                style={style}>
                {datum}
            </td>)
        })


    }
  
    return <div className="usage-stats-legend-container">
            <table className="league-table usage-stats-legend">
            <thead>
                <tr><th className="clickable-legend-header" colSpan={maxRowSize}>Legend</th></tr>
            </thead>
            <tbody>
                {legendRows}
            </tbody>
            </table>
            <table className="league-table usage-stats-legend">
                <tbody>
                    <tr>{playerRow}</tr>
                </tbody>
            </table>
        </div>
  
  }

  buildUsageRow(datum) {
    const temp = []
    const datumKey = datum["player_name"] + "|" + datum["pokemon_id"]
    console.log(this.state.iconView)
    if (this.state.iconView === false) {
      temp.push(<td>
        {this.generatePokemonSpriteCell(datumKey, datum["pokemon_id"])}
      </td>)
    } else {
      temp.push(<td>
        {this.generatePokemonIconCell(datumKey, datum["pokemon_id"])}
      </td>)
    }

    temp.push(<td>{Math.round(1000 * datum["usage_pct"]) /10.0 + "%"}</td>)
    temp.push(<td>{(datum["win_rate"] !== null ? Math.round(1000 * datum["win_rate"]) /10.0 + "%" : "--")}</td>)
    temp.push(<td>{datum["games_available"]}</td>)
    temp.push(<td>{datum["game_count"]}</td>)
    temp.push(<td>{datum["num_victories"]}</td>)
    return temp
  }

  sortAndFilterPokemonData(pokemonDataArray) {
    const temp = this.sortPokemonData(pokemonDataArray);
    return temp.filter(
        datum => {return datum["game_count"] >= this.state.thresholdLimit}
      ).filter(
        datum => {
          if (this.state.searchPokemon === "") {return true}
          else {
            // Check if the base form matches
            const baseDatum = this.pokemonInfo.base[datum["pokemon_id"]];
            const baseFormMatches = baseDatum["pokemon_name"].toLowerCase() === this.state.searchPokemon.toLowerCase() |
              baseDatum["pokemon_name"].toLowerCase().includes(this.state.searchPokemon.toLowerCase())

            // If there's a transformation, check that too
            const transfDatum = this.pokemonInfo.transf[datum["pokemon_id"]];
            let transfFormMatches = false;
            if (transfDatum !== undefined) {
              transfFormMatches = transfDatum["pokemon_name"].toLowerCase() === this.state.searchPokemon.toLowerCase() |
                transfDatum["pokemon_name"].toLowerCase().includes(this.state.searchPokemon.toLowerCase())
            }
            return baseFormMatches | transfFormMatches
          }
        }
      ).filter(
        datum => {
          if (this.state.activePlayer === undefined) {return true}
          else {
            console.log(datum)
            if (!this.state.displayNotOnRoster) {
              return datum["on_player_roster"]
            } else {
              return true
            }
          }
        }
      );
  }

  sortPokemonData(pokemonDataArray) {
    if (pokemonDataArray.length === 0) return pokemonDataArray;

    // Infer the type we're supposed to deal with
    let refType = typeof(pokemonDataArray[0][this.state.sortingKey]);
    if (refType === "number") {
      pokemonDataArray = pokemonDataArray.sort((a, b) => {
        let bVal = b[this.state.sortingKey]
        let aVal = a[this.state.sortingKey]

        // Nulls should be below everything
        if (bVal === null && bVal === aVal) {
          bVal = 0;
          aVal = 0
        } else if (bVal === null) {
          bVal = aVal - 1
        } else if (aVal === null) {
          aVal = bVal - 1
        }
        let valDiff = bVal - aVal;

        // If we have a difference, use this
        if (valDiff !== 0) {
          return valDiff
        }
        // Otherwise just sort alphabetically
        return -1 * b["pokemon_id"].localeCompare(a["pokemon_id"])
      })
    } else if (refType === "string") {
      pokemonDataArray = pokemonDataArray.sort((a, b) => a[this.state.sortingKey].localeCompare(b[this.state.sortingKey]))
    }

    if (this.state.sortOrderDesc !== true) {
      pokemonDataArray = pokemonDataArray.reverse();
    }

    return pokemonDataArray
  }

  buildTable() {
    // Build Table header
    const tableHeader = []
    tableHeader.push(<th>Rank</th>)
    tableHeader.push(<th>Pok&#233;mon</th>)
    tableHeader.push(<th>Usage Pct.</th>)
    tableHeader.push(<th>Win Pct.</th>)
    tableHeader.push(<th>Games Available</th>)
    tableHeader.push(<th>Games Played</th>)
    tableHeader.push(<th>Games Won</th>)

    const tableRows = []
    let counter = 1;
    let dataTarget = JSON.parse(JSON.stringify(this.data.player))
    if (this.state.activeConference === undefined) {
      dataTarget = this.data.pokemon
    }
    if (this.state.activePlayer !== undefined && (Object.keys(this.data.player).length !== 0)) {
      tableHeader.push(<th>Win Share</th>)
      tableHeader.push(<th>On Roster</th>)
      const pokemonData = this.sortAndFilterPokemonData(dataTarget[this.state.activePlayer])
      pokemonData.forEach((datum) => {
        const temp = this.buildUsageRow(datum)
        temp.unshift(<td className="counter-td">{counter}</td>)
        counter += 1
        if (datum["pokemon_win_share"] !== null) {
          temp.push(<td>{Math.round(1000 * datum["pokemon_win_share"]) /10.0 + "%"}</td>)
        } else {
          temp.push(<td>--</td>)
        }

        if (datum["on_player_roster"]) {
          temp.push(<td><b>Yes</b></td>)
        } else {
          temp.push(<td><i>No</i></td>)
        }

        tableRows.push(<tr>{temp}</tr>)
      });
    } else if (this.data.pokemon.length !== 0) {
      tableHeader.push(<th>Players</th>)
      const updatePlayerFunc = this.updateActivePlayer;
      dataTarget = this.sortAndFilterPokemonData(dataTarget)

      dataTarget.forEach((datum) => {
        const temp = this.buildUsageRow(datum)
        temp.unshift(<td className="counter-td">{counter}</td>)
        counter += 1
        const playerSpans = []
        datum["players"].forEach(playerDatum => {
          const playerName = playerDatum.player_name
          const playerConf = lookupPlayerConference(this.playerInfo, playerName)
          const eltStyle = this.seasonConfig["LEAGUE_TABLE"]["CONFERENCE"][playerConf];
          let gameShare = <span></span>
          if (playerDatum.game_share > 0) {
            gameShare = <span>({Math.round(playerDatum.game_share)}%)</span>
          }
          playerSpans.push(<span key={playerName} onClick={() => {updatePlayerFunc(playerName)}} className="player-name-span" style={eltStyle}>
            {playerName} {gameShare}<br/></span>)
        });
        temp.push(<td>
          {playerSpans}
        </td>)
        tableRows.push(<tr>{temp}</tr>)
      })
    }

    return <table className="league-table table-responsive table-striped">
        <thead>
            <tr>
                {tableHeader}
            </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </table>

  }

  updateSortingVariable(newVar) {
    if (this.state.sortingKey === newVar) {
      return;
    }
    this.setState({
      sortingKey: newVar
    })
  }

  updateSortingOrder(boolValue) {
    console.log(boolValue)
    this.setState({
      sortOrderDesc: boolValue
    })
  }

  generateSortingButtons() {
    const sortVariables = ["pokemon_id", "usage_pct", "win_rate",
      "games_available", "game_count", "num_victories"]
    const sortNames = ["Pokémon", "Usage Pct.", "Win Pct.",
      "Games Available", "Games Played", "Games Won"]
    
    if (this.state.activePlayer !== undefined && (Object.keys(this.data.player).length !== 0)) {
      sortVariables.push("pokemon_win_share")
      sortNames.push("Win Share")
    }

    const buttons = []
    for (let i = 0; i < sortVariables.length; ++i) {
      let sortVar = sortVariables[i];
      let sortName = sortNames[i];
      let btnClass = "btn";
      if (sortVar === this.state.sortingKey) {
        btnClass = btnClass.concat(" btn-primary")
      } else {
        btnClass = btnClass.concat(" btn-dark")
      }

      buttons.push(<button
        onClick={() => {this.updateSortingVariable(sortVar)}}
        key={sortVar}
        type="button"
        className={btnClass}>
          {sortName}</button>)
    }

    return <div>
      <div className="btn-toolbar sort-button-bar" role="toolbar">
        <div className="btn-group" role="group" aria-label="Basic example">
          {buttons}
        </div> &nbsp;&nbsp;&nbsp;
        <div className="btn-group" role="group" aria-label="Third group">
          <button type="button" onClick={() => this.updateSortingOrder(false)} className={"triggerButton btn " + (this.state.sortOrderDesc ? "btn-dark" : "btn-primary")}>Ascending</button>
          <button type="button" onClick={() => this.updateSortingOrder(true)} className={"triggerButton btn " + (this.state.sortOrderDesc ? "btn-primary" : "btn-dark")}>Descending</button>
        </div>
      </div>
      <div style={{"margin":"auto", display: "table"}}>
          <label className="gut-label">Pok&eacute;mon Search:</label> &nbsp;
          <input type="text" value={this.state.searchPokemon} onChange={this.updateSearchPokemon} style={{width: "150px"}} /> &nbsp;&nbsp;
          <label className="gut-label">Minimum Games Played:</label> &nbsp;
          <input type="text" value={this.state.thresholdLimit} onChange={this.updateThresholdLimit} style={{width: "30px"}}/>
      </div>
      </div>
  }

  generateCurrentRosterButton() {
    const currentDisplayFlag = this.state.displayNotOnRoster;
    if (this.state.activePlayer !== undefined) {
      return <button
        type="button"
        onClick={() => {this.setState({"displayNotOnRoster": !currentDisplayFlag})}}
        className={"triggerButton btn btn-dark"}>
          {currentDisplayFlag ? "Roster History" : "Current Roster"}
      </button>
    } else {
      return ""
    }
  }

  render() {
    const body = <div className="usage-stats-table-div">
      {this.buildClickableLegend(this.seasonNum)}
      <div className="table-container">

        {Object.keys(this.pokemonInfo.base).length !== 0 ? this.buildTable() : <div></div>}
      </div>
    </div>

    return (
      <div className="about">
        <div className="container-fluid">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <h1 className="font-weight-light">Season #{ this.seasonNum } Usage Stats</h1>
              <div className="row align-items-center button-bar">
                {this.generateDetailsButton()}
                {this.generateIconButton()}
                <DataDumpButton />
                {this.generateCurrentRosterButton() }
              </div>
              <div className="row align-items-center sort-button-bar">
                {this.generateSortingButtons()}
              </div>
      
              {body}
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default UsageStats;