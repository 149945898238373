import React from "react";
import { parseSeasonFromPath, checkEmptyObject, capitalizeFirstLetter,
  getGameData, getPokemonDetailsInfoHelper} from './helpers';
// import configData from "../config.json";
import '../component_css/GameDetailsView.css';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


function generateHeader(datum) {
  let displayStr = ""
  if (datum.playoffs_flag) {
    displayStr += "[Playoffs] "
  }

  displayStr += "Round " + datum.round + " "
  if (datum.playoffs_flag) {
    displayStr += "Game " + datum.playoff_game_num + " "
  }
  displayStr += datum.player1 + " vs. " + datum.player2
  return displayStr
}

function generateGameSummary(summaryKey, summaryInfo, rosterNames, rosterSRCs) {
  // Get the game summary for Players
  const p1Leads = []
  const p1Faints = []
  const p1Used = []
  summaryInfo.fainted[summaryKey].forEach((datum) => {
    const datumIdx = rosterNames.indexOf(datum);
    p1Faints.push(
      <img key={datum + datumIdx} src={rosterSRCs[datumIdx]} alt={datum} />
    )
  });
  summaryInfo.leads[summaryKey].forEach((datum) => {
    const datumIdx = rosterNames.indexOf(datum);
    p1Leads.push(
      <img key={datum + datumIdx} src={rosterSRCs[datumIdx]} alt={datum} />
    )
  });
  summaryInfo.pokemon_used[summaryKey].forEach((datum) => {
    const datumIdx = rosterNames.indexOf(datum)
    p1Used.push(
      <img key={datum + datumIdx} src={rosterSRCs[datumIdx]} alt={datum} />
    )
  })

  return <div className="game-summary-block">
    <ul>
      <li>
        Leads: {p1Leads}
      </li>
      <li>
        Pok&eacute;mon Used: {p1Used}
      </li>
      <li>
      Pok&eacute;mon Fainted: {p1Faints}
      </li>
    </ul>
  </div>
}

function totalAmount(damageInfo) {
  let output = 0
  for (let key of Object.keys(damageInfo)) {
    if (key === "faint") continue
    
    if (typeof(damageInfo[key]) === "object") {
      output += totalAmount(damageInfo[key])
    } else if(typeof(damageInfo[key]) === "number") {
      output += 100 * damageInfo[key]
    } else {
      console.log("Unknown type: ", key, typeof(damageInfo[key]))
    }
  }
  return Math.round(output)
}


class GameDetailsView extends React.Component {

  constructor() {
    super()

    this.seasonNum = parseSeasonFromPath(window.location.pathname)
    this.setChosenGame = this.setChosenGame.bind(this);
    this.setChosenGameLogic = this.setChosenGameLogic.bind(this)
    this.renderChosenGame = this.renderChosenGame.bind(this);
    this.updateChosenPokemon = this.updateChosenPokemon.bind(this);
    this.buildBreakdownList = this.buildBreakdownList.bind(this);

    this.pokemonData = {}
    this.gameData = []
    this.state = {
      validGameURLID: true,
      chosenGameIdx: 1,
      chosenGameURLID: -1,
      chosenGameData: undefined,
      chosenP1Pokemon: null,
      chosenP2Pokemon: null
    }

  }

  setChosenGameLogic(new_idx) {
    const api_endpoint = configData.SERVER_URL + this.seasonNum + "/gamedetails/" + this.gameData[new_idx]["game_id"]
    return fetch(api_endpoint)
    .then(res => res.json(),
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        alert(error);
      })
    .then(
      results => {
        if (results === undefined) {
          return;
        }
        const gameUrlID = this.gameData[new_idx]["game_url_id"]
        if (window.location.pathname.endsWith("gamedetails")) {
          // No game chosen, just set to the correct path
          window.history.pushState({}, null, "gamedetails/".concat(gameUrlID))
        } else {
          window.history.pushState({}, null, gameUrlID);
        }
        this.setState({
          chosenGameIdx: new_idx,
          validGameURLID: true,
          chosenGameURLID: gameUrlID,
          chosenGameData: results,
          chosenP1Pokemon: null,
          chosenP2Pokemon: null,
        });
      },
      (error) => {

      }
    )
    .catch(error => console.warn(error));
  }

  setChosenGame(new_idx) {
    try {
      this.setChosenGameLogic(new_idx)
    } catch(e) {
      this.setState({validGameURLID: false})
    }
  }

  componentDidMount() {
    getGameData(this.seasonNum).then(
      (results) => {
        this.gameData = results;
        // Also figure out the active game
        let gameID = this.state.chosenGameIdx
        if (!checkEmptyObject(this.props.gameID)) {
          gameID = this.props.gameID
        }

        // Figure out how to set the state
        let chosenIdx = this.gameData.reduce(
          (out, datum, idx) =>
            ("" + datum["game_url_id"] === "" + gameID ? out.concat(idx) : out), []
        )
        if (chosenIdx === []) {
          chosenIdx = 0
        } else {
          chosenIdx = chosenIdx[0]
        }
        this.setChosenGame(chosenIdx)
      },
      (error) => {
        console.warn(error)
      }
    ).then(
      () => {return getPokemonDetailsInfoHelper(this.seasonNum)}
    ).then(
      (results) => {this.pokemonData = results;}
    ).catch(error => console.warn(error))
  }

  updateChosenPokemon(player, pokemonName) {
    if (player === "p1") {
      if (this.state.chosenP1Pokemon === pokemonName) {
        this.setState({
          chosenP1Pokemon: null
        })
      } else {
        this.setState({
          chosenP1Pokemon: pokemonName
        })
      }
    } else if (player === "p2") {
      if (this.state.chosenP2Pokemon === pokemonName) {
        this.setState({
          chosenP2Pokemon: null
        })
      } else {
        this.setState({
          chosenP2Pokemon: pokemonName
        })
      }
    }
  }

  buildBreakdownList(damageInfo) {
    const output = []
    for (let key of Object.keys(damageInfo)) {
      if (key === "faint") {
        continue
      }
      else if (key === "misc") {
        for (let subKey of Object.keys(damageInfo.misc)) {
          output.push(<li key={key + subKey}>
            {capitalizeFirstLetter(subKey)}: {Math.round(100.0 * damageInfo[key][subKey])}%
            </li>)
        }
      } else {
        if (typeof(damageInfo[key]) === "object") {
          output.push(<li key={key}> {capitalizeFirstLetter(key)}: {totalAmount(damageInfo[key])}%
            <ul>
              {this.buildBreakdownList(damageInfo[key])}
            </ul>
          </li>)
        } else {
          let label = capitalizeFirstLetter(key)
          try {
            label = <img src={this.pokemonData[key].dex_sprite_url} title={key} alt={key} />
          } catch (error) {}
  
          output.push(<li key={key}>
            {label}: {Math.round(100.0 * damageInfo[key])}%
            </li>)
        }
      }
    }
    return output
  }

  generateActivePokemon(pokemonInfo, pokemonSpriteSRC, activePokemon) {
    if (pokemonInfo === undefined) {
      pokemonInfo = {}
    }

    // Form CHanges
    let formChangeList = []
    if (pokemonInfo.static_forms) {
      formChangeList.push(<div id="form_change"><b>Form:</b> {pokemonInfo.static_forms}</div>)
    }
    if (pokemonInfo.form_changes) {
      formChangeList.push(<div id="form_change"><b>Transformation:</b> {pokemonInfo.form_changes}</div>)
    }
    let formChangeInfo = null;
    if (formChangeList.length === 0) {
      // formChangeInfo = <div className="empty-field">No Form Changes</div>
      formChangeInfo = ""
    } else {
      formChangeInfo = <div>
        {formChangeList}
      </div>
    }

    // Move Usage
    let moveUsageInfo = <div className="empty-field">No Moves Used</div>
    if (pokemonInfo.moves_used) {
      let moves = []
      pokemonInfo.moves_used.forEach((datum) => {
        moves.push(<li key={datum}>
          {datum}
        </li>)
      })
      moveUsageInfo = <div>
        <b>Moves Used:</b>
        <ul>{moves}</ul>
      </div>
    }

    // Logic for Damage Dealt
    let damageDealtInfo = <div className="empty-field">No Damage Dealt</div>
    if (pokemonInfo.damage_dealt) {
      let damageBreakdown = this.buildBreakdownList(pokemonInfo.damage_dealt)

      // Faint Information
      let faintInfo = <div></div>
      if (pokemonInfo.damage_dealt.faint) {
        let fainted = []
        pokemonInfo.damage_dealt.faint.forEach((datum) => {
          fainted.push(<li key={datum}>
            <img src={this.pokemonData[datum].dex_sprite_url} title={datum} alt={datum} />
          </li>)
        });
        faintInfo = <div>
          <b>Pokemon KO'ed</b>
          <ul>{fainted}</ul>
        </div>
      }

      damageDealtInfo = <div>
        {faintInfo}
        <b>Damage Dealt</b>
        <ul>
          {damageBreakdown}
        </ul>
      </div>
    }

    // Logic for damage Healed
    let damageHealedInfo = <div className="empty-field">No Damage Healed</div>
    if (pokemonInfo.damage_healed) {
      damageHealedInfo = <div>
        <b>Damage Healed</b>
        <ul>{this.buildBreakdownList(pokemonInfo.damage_healed)}</ul>
      </div>
    }

    // Logic for Damage Taken
    let damageTakenInfo = <div className="empty-field">No Damage Taken</div>
    if (pokemonInfo.damage_received) {
      let faintInfo = <div></div>
      if (pokemonInfo.damage_received.faint) {
        try {
          faintInfo = <div><b>KO'ed by:</b>&nbsp;
            <img src={this.pokemonData[pokemonInfo.damage_received.faint].dex_sprite_url}
              title={pokemonInfo.damage_received.faint} alt={pokemonInfo.damage_received.faint} />
          </div>
        } catch {
          faintInfo = <div><b>KO'ed by: </b> {capitalizeFirstLetter(pokemonInfo.damage_received.faint[0])}</div>
        }
      }

      const damageBreakdownList = this.buildBreakdownList(pokemonInfo.damage_received)
      let damageTakenBreakdown = <div></div>
      if (damageBreakdownList.length > 0) {
        damageTakenBreakdown = <div>
          <b>Damage Taken</b>
          <ul>
            {damageBreakdownList}
          </ul>
        </div>
      }

      damageTakenInfo = <div>
        {faintInfo}
        {damageTakenBreakdown}
      </div>
    }

    // Logic for Items
    let itemInfo = <div className="empty-field">No Item Revealed</div>
    if (pokemonInfo.items) {
      itemInfo = <div><b>Item: </b> {pokemonInfo.items[0]}</div>
    }

    // Logic for Turns on field
    let turnInfo = <div className="empty-field">Not Used in Game</div>
    if (pokemonInfo.turn_counts) {
      turnInfo = <div><b>Turns on Field:</b> {pokemonInfo.turn_counts}</div>
    }

    return <div>
      <h4>{activePokemon} <img src={pokemonSpriteSRC} alt={activePokemon} /></h4> 
      {formChangeInfo}
      {itemInfo}
      {turnInfo}
      {moveUsageInfo}
      {damageDealtInfo}
      {damageHealedInfo}
      {damageTakenInfo}
    </div>
  }

  renderChosenGame() {
    const gameData = this.gameData[this.state.chosenGameIdx];
    if (gameData === undefined) {
      return <div></div>
    }
    let p1ChosenPokemonSRC = null;
    let p2ChosenPokemonSRC = null;

    // Get the Roster Icons
    const p1RosterSprites = []
    gameData.player1_roster_full_sprites.forEach((imgSRC, idx) => {
      const origPokemonName = gameData.player1_roster[idx];
      let pokemonName = gameData.player1_roster[idx];
      let actualSRC = imgSRC;
      try {
        let key = gameData.player1 + "_info";
        if (this.state.chosenGameData[key][pokemonName]["form_changes"] !== undefined) {
          pokemonName = this.state.chosenGameData[key][pokemonName]["form_changes"]
          actualSRC = this.pokemonData[pokemonName].sprite_url
        }
      } catch {}

      if (origPokemonName === this.state.chosenP1Pokemon) {
        p1ChosenPokemonSRC = gameData.player1_roster_icons[idx]
      }

      p1RosterSprites.push(<img
        onClick={() => {this.updateChosenPokemon("p1", origPokemonName)}}
        className={"game-details-roster-img " + (origPokemonName === this.state.chosenP1Pokemon ? "selected-pokemon": "")}
        key={pokemonName}
        alt={pokemonName} src={actualSRC} />)
    })
    const p2RosterSprites = []
    gameData.player2_roster_full_sprites.forEach((imgSRC, idx) => {
      const origPokemonName = gameData.player2_roster[idx];
      let pokemonName = gameData.player2_roster[idx];
      let actualSRC = imgSRC;
      try {
        let key = gameData.player2 + "_info";
        if (this.state.chosenGameData[key][pokemonName]["form_changes"] !== undefined) {
          pokemonName = this.state.chosenGameData[key][pokemonName]["form_changes"]
          actualSRC = this.pokemonData[pokemonName].sprite_url
        }
      } catch {}

      if (origPokemonName === this.state.chosenP2Pokemon) {
        p2ChosenPokemonSRC = gameData.player2_roster_icons[idx]
      }

      p2RosterSprites.push(<img
        onClick={() => {this.updateChosenPokemon("p2", origPokemonName)}}
        className={"game-details-roster-img " + (origPokemonName === this.state.chosenP2Pokemon ? "selected-pokemon": "")}
        key={pokemonName}
        alt={pokemonName} src={actualSRC} />)
    })

    // Generate the game summary
    let p1GameSummary = <div></div>
    let p2GameSummary = <div></div>
    if (!checkEmptyObject(this.state.chosenGameData)) {
      p1GameSummary = generateGameSummary(
        gameData.player1, this.state.chosenGameData,
        gameData.player1_roster, gameData.player1_roster_icons
      )
      p2GameSummary = generateGameSummary(
        gameData.player2, this.state.chosenGameData,
        gameData.player2_roster, gameData.player2_roster_icons
      )
    }

    //Generate summary for active Pokemon
    let p1ActiveSummary = <center><i>Click on a Pok&eacute;mon to see the stats!</i></center>
    let p2ActiveSummary = <center><i>Click on a Pok&eacute;mon to see the stats!</i></center>
    if (!checkEmptyObject(this.state.chosenGameData) && !checkEmptyObject(this.state.chosenP1Pokemon)) {
      p1ActiveSummary = this.generateActivePokemon(
        this.state.chosenGameData[gameData.player1 + "_info"][this.state.chosenP1Pokemon],
        p1ChosenPokemonSRC,
        this.state.chosenP1Pokemon
      )
    }
    if (!checkEmptyObject(this.state.chosenGameData) && !checkEmptyObject(this.state.chosenP2Pokemon)) {
      p2ActiveSummary = this.generateActivePokemon(
        this.state.chosenGameData[gameData.player2 + "_info"][this.state.chosenP2Pokemon],
        p2ChosenPokemonSRC,
        this.state.chosenP2Pokemon
      )
    }

    return <div className="container-fluid h-100">
      <h1>{generateHeader(gameData)}</h1>
        <a href={gameData.replay_url} target="_blank" rel="noreferrer">
         <button className="btn btn-dark">Link to Game</button>
        </a>
      <p>Chosen game: {this.state.chosenGameURLID}</p>

      {/* For Desktop viewing */}
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-md-6"><center><h2>{gameData.player1 + "'s Roster"}</h2></center></div>
          <div className="col-md-6"><center><h2>{gameData.player2 + "'s Roster"}</h2></center></div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6 game-details-roster-container">
            {p1RosterSprites}
          </div>
          <div className="col-md-6 game-details-roster-container">
            {p2RosterSprites}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 game-details-roster-container">
            {p1GameSummary}
          </div>
          <div className="col-md-6 game-details-roster-container">
            {p2GameSummary}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {p1ActiveSummary}
          </div>
          <div className="col-md-6">
            {p2ActiveSummary}
          </div>
        </div>
      </div>

      {/* Only on Mobile */}
      <div className="row d-md-none">
        <div className="col-md-6">
          {/* Player 1's stuff */}
          <div className="col-md-12"><center><h2>{gameData.player1 + "'s Roster"}</h2></center></div>
          <div className="row align-items-center">
            <div className="col-md-12 game-details-roster-container">
                {p1RosterSprites}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 game-details-roster-container">
              {p1GameSummary}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {p1ActiveSummary}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {/* Player 2's stuff */}
          <div className="col-md-12"><center><h2>{gameData.player2 + "'s Roster"}</h2></center></div>
          <div className="row align-items-center">
            <div className="col-md-12 game-details-roster-container">
              {p2RosterSprites}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 game-details-roster-container">
              {p2GameSummary}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {p2ActiveSummary}
            </div>
          </div>
        </div>

      </div>
    </div>
  }

  generateGameSelector() {
    const options = [];

    this.gameData.forEach((datum, idx) => {
      options.push(<option value={idx}>
          {generateHeader(datum)}
      </option>)
    })

    return <label>Chosen Game:&nbsp;
        <select name={"gameSelector"}
            value={this.state.chosenGameIdx}
            onChange={(e) => this.setChosenGame(e.target.value)}>
            {options}
        </select>
    </label> 
  }

  render() {
    let gameDiv = <div>
      <h1>Invalid Game ID</h1>
      <p>Please select a game from the dropdown menu.</p>
    </div>;
    if (this.state.validGameURLID) {
      gameDiv = this.renderChosenGame()
    }
    return (
      <div className="basepage">
      <div className="container-fluid">
        <div className="row align-items-center my-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            {this.generateGameSelector()}
            {gameDiv}
          </div>
        </div>
      </div>
    </div>
    );
  }
}
  
  export default GameDetailsView;