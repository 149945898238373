import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, GameDetailsView, BasePage, Main, Rules, Roster, GameData, LeagueTable, TradeLog, Draft, UsageStats, BattleStats, AdminPage, TeamAnalyzer, FreeAgentsPage, ReverseLookup, STABAnalyzer, PairwiseUsage, SpeedTierViewer, RosterValidation} from "./components";
function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <BasePage />}/>
          <Route path="/:seasonNum/rules" exact component={() => <Rules />} />
          <Route path="/:seasonNum/roster" exact component={() => <Roster />} />
          <Route path="/:seasonNum/gamedata" exact component={() => <GameData />} />
          <Route path="/:seasonNum/leaguetable" exact component={() => <LeagueTable />} />
          <Route path="/:seasonNum/tradelog" exact component={() => <TradeLog />} />
          <Route path="/:seasonNum/draft" exact component={() => <Draft />} />
          <Route path="/:seasonNum/usagestats" exact component={() => <UsageStats />} />
          <Route path="/:seasonNum/battlestats" exact component={(props) => <BattleStats seasonNum={props.match.params.seasonNum} />} />
          <Route path="/:seasonNum/gamedetails/:gameID" exact component={(props) => <GameDetailsView gameID={props.match.params.gameID} />} />
          <Route path="/:seasonNum/gamedetails" exact component={() => <GameDetailsView />} />
          <Route path="/:seasonNum/admin" exact component={() => <AdminPage />} />
          <Route path="/:seasonNum/teamanalyzer" exact component={() => <TeamAnalyzer />} />
          <Route path="/:seasonNum/freeagents" exact component={() => <FreeAgentsPage />} />
          <Route path="/:seasonNum/reverselookup" exact component={(props) => <ReverseLookup seasonNum={props.match.params.seasonNum} />} />
          <Route path="/:seasonNum/stabanalyzer" exact component={() => <STABAnalyzer />} />
          <Route path="/:seasonNum/pairwiseusage" exact component={() => <PairwiseUsage />} />
          <Route path="/:seasonNum/speedtiers" exact component={() => <SpeedTierViewer />} />
          <Route path="/:seasonNum/teamvalidation" exact component={() => <RosterValidation />} />
          <Route path="/:seasonNum" exact component={() => <Main />} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
