import React from "react";
import {useParams} from "react-router-dom";
import '../component_css/MainPage.css'
// import configData from "../config.json";
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


function generatePOTD(potdConfig) {
  console.log(potdConfig)

  const moveHTML = []
  potdConfig.moves.forEach(datum => {
    moveHTML.push(<div className="move">
          <div className="top">
            <h4 className={"type "}> 
              <img src={configData.TYPE_IMAGES[datum.type]} alt={datum.type} />
            </h4>
            <h3 className="name">{datum.name}</h3>
            <h3 className="power">{datum.power}</h3>
          </div>
          <div className="description">
            {datum.description}
          </div>
        </div>)
  })

  let ivHTML = ""
  if (potdConfig.ivString) {
    ivHTML = <h3 className="ivs">IVs <span>{potdConfig.ivString}</span></h3>
  }

  return <div className="potd">
  <h1 className="potd-header">Pokemon Highlights</h1>
  <div className="col-lg-7 potd-container">
    
    <div className="shadow"></div>
    <div className="pokemon-card">

      <div className="card-header">
        <div className="left">
          <h3 className={"type "}>
            <img src={configData.TYPE_IMAGES[potdConfig.type]} alt={potdConfig.type} />
          </h3>
          <h2 className="name">{potdConfig.name} <span className="poke-num">No.{potdConfig.number}</span></h2>
        </div>
        <div className="hp">HP <span>{potdConfig.stats.hp}</span></div>
      </div>
      
      <div className="image-container">
        <img className="main-pic" src={potdConfig.mainPic} alt="" />
        <img className="gif-pic" src={potdConfig.gifPic} alt="" />
        <h4 className="stats">atk:{potdConfig.stats.atk}  def:{potdConfig.stats.def}  spAtk:{potdConfig.stats.spa}  spDef:{potdConfig.stats.spd}  spe:{potdConfig.stats.spe}</h4>
      </div>
      <div className="moves-container">
        {/* <h2>Moveset(s):</h2> */}
        <div className="set-info">
          <h3 className="ability">Ability <span>{potdConfig.ability}</span></h3>
          <h3 className="item">Item <span>{potdConfig.item}</span></h3>
          <h3 className="nature">Nature <span>{potdConfig.nature}</span></h3>
        </div>
        {moveHTML}
        <div className="ev-iv">
          <h3 className="evs">EVs <span>{potdConfig.evString}</span></h3>
          {ivHTML}
        </div>
      </div>
      <div></div>
      
    </div>
  </div>
</div>

}


function Main() {
  let { seasonNum } = useParams()
  let seasonConfig = configData.LEAGUE_CONFIG[seasonNum]

  const potdChoices = seasonConfig["POTD"]
  const potdChosen = potdChoices[Math.floor(Math.random() * potdChoices.length)]

  const bracketLink = seasonConfig.GAMEDATA.PLAYOFFS.bracketLink
  let playoffHeader = <div></div>
  let playoffBracket = <div></div>
  if (bracketLink) {
    playoffBracket = <div className="bracket-container">
        <iframe title="playoff-bracket" src={bracketLink} width="100%" height="500" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
      </div>
    playoffHeader = <h5>Playoffs Bracket</h5>
  }

  let mainTitle = "QPL Season ".concat(seasonNum)
  if (seasonConfig.MAIN_HEADER) {
    mainTitle = seasonConfig.MAIN_HEADER
  }


  return (
    <div className="home">
      <div className="container">
        {generatePOTD(potdChosen)}
        <div className="season-header">
          <h1>{ mainTitle }</h1>
          {playoffHeader}
          {playoffBracket}
          </div>
      </div>
    </div>
  );
}

export default Main;