import React from "react";
import FreeAgentsPage from "./FreeAgents";
import { parseSeasonFromPath, getReverseLookupInfo, getPlayerConferenceInfo, lookupPlayerConference, groupPlayersConference, generateListHelper } from './helpers';

class ReverseLookup extends FreeAgentsPage {
    constructor() {
        super()
        this.state = {
          ...this.state,
          hideNFEs: false,
          detailsView: true
        }
        this.seasonNum = parseSeasonFromPath(window.location.pathname)
        this.rosterData = {}
        this.playerInfo = {};
        this.pageTitle = <h1 className="font-weight-light">Season #{ this.seasonNum } Pok&eacute;mon Roster Lookup</h1>;

        this.buildTableHTML = this.buildTableHTML.bind(this);
    }

    componentDidMount() {
      this.getPokemonInfo().then(
        (results) => {
          this.pokemonInfo = results
        }
      ).then(
        () => {
          return getReverseLookupInfo(this.seasonNum)
        }
      ).then(
        (results) => {
          this.rosterData = results;
        }
      ).then(
        () => {
          return getPlayerConferenceInfo(this.seasonNum);
        }).then(
          (results) => {
            this.playerInfo = groupPlayersConference(results);
          }
        ).finally(
        () => {
          this.setState({
            detailsView: true,
            hideNFEs: false
          })
        }
      )
    }

    buildTableHTML() {
      const outputRows = []
      const activePokemon = this.state.activePokemon;

      let pokemonKeys = Object.keys(this.rosterData)

      if (this.state.statFilterKey !== "") {
        pokemonKeys = pokemonKeys.sort((a, b) => {
          let aKey = "base"
          if (activePokemon.indexOf(a) !== -1) {
            aKey = "transf"
          }

          let bKey = "base";
          if (activePokemon.indexOf(b) !== -1) {
            bKey = "transf"
          }

          const aDetails = this.pokemonInfo[aKey][a];
          const bDetails = this.pokemonInfo[bKey][b];
          return (aDetails.stats[this.state.statFilterKey] > bDetails.stats[this.state.statFilterKey]) ? -1 : 1
        })
      }

      pokemonKeys.forEach(pokeID => {
        if (!this.checkKeyMatchesFilterCriteria(pokeID)) {
          return
        }   

        let pokemonDatum = this.pokemonInfo.base[pokeID]
        if (activePokemon.indexOf(pokeID) !== -1) {
          pokemonDatum = this.pokemonInfo.transf[pokeID]
        }

        // List of Moves
        const temp = this.buildFAInfoColumns(pokeID, pokemonDatum);

        // Player Names Cell
        const playerSpans = []
        this.rosterData[pokeID].forEach(playerDatum => {
          const playerName = playerDatum.player_name
          const playerConf = lookupPlayerConference(this.playerInfo, playerName)
          const eltStyle = this.seasonConfig["LEAGUE_TABLE"]["CONFERENCE"][playerConf];

          playerSpans.push(<a href={ "/" + this.seasonNum + "/roster?player=" + playerName} >
            <span key={playerName} className="player-name-span" style={eltStyle}>
             {playerName} <br/></span> </a>)
        });
        temp.push(<td>
          {playerSpans}
        </td>)

        // Finished
        outputRows.push(<tr>{temp}</tr>)
      });

      const headerRow = this.buildTableHeaderCols();

      return <table className="league-table table-responsive table-striped">
        <thead>
          <tr>
            {headerRow}
            <th>Currently Held By</th>
          </tr>
        </thead>
        <tbody>
          {outputRows}
        </tbody>

      </table>
    }

    
}

export default ReverseLookup;