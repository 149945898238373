import React from "react";
// import configData from "../config.json";
import "../component_css/TeamAnalyzer.css"

import { parseSeasonFromPath, getPlayerConferenceInfo } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();
  

class RosterValidation extends React.Component {
  constructor() {
    super()
    this.state = {
        chosenPlayer: "",
        importableText: "",
        validText: "Click submit to test validity!"
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum];
    this.playerInfo = []

    this.setPlayer = this.setPlayer.bind(this);
    this.updateImportableText = this.updateImportableText.bind(this);
  }

  componentDidMount() {
    getPlayerConferenceInfo(this.seasonNum).then(
      results => {this.playerInfo = results;}
    ).finally(
      () => {
        this.setState({chosenPlayer: this.playerInfo[0].player_name})
      }
    )
  }

  setPlayer(newPlayer) {
    this.setState({chosenPlayer: newPlayer})
  }

  generatePlayerDropdown(label, func, val) {
    const options = []
    this.playerInfo.forEach(obj => {
        const value = obj.player_name;
        options.push(<option key={value} value={value}>
            {value}
        </option>)
    })
    return <div className="player-dropdown-div">
        <label>{label}:&nbsp;
        <select name={"gameSelector"}
            value={val}
            onChange={(e) => func(e.target.value)}>
            {options}
        </select>
    </label> 
    </div>
  }

  updateImportableText(e) {
    this.setState({importableText: e.target.value});
  }


  onSubmit(e) {
    e.preventDefault();
    var reqData = new FormData();
    reqData.append('textData', JSON.stringify(this.state.importableText));
    reqData.append('playerName', JSON.stringify(this.state.chosenPlayer));

    const api_endpoint = configData.SERVER_URL + this.seasonNum + "/team_validator"
    return fetch(api_endpoint, {
        method: "POST",
        body: reqData
      })
      .then(res => {
        if (res.status !== 200) {
            throw res;
        }
        return res.json()
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(error);
        }
      ).then(
            res => {
                alert(res.message);
                this.setState({validText: res.message});
            }
        )
      .catch((error) => {
        console.warn(error);
        alert("There was a server error. Ensure that the data/password provided are valid")
      });
    }

  render() {
    const dropdown = this.generatePlayerDropdown("Player", this.setPlayer, this.state.chosenPlayer);
    return (
      <div className="about">
        <div className="container-fluid h-100">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <h1 className="font-weight-light">Season #{ this.seasonNum } Team Validator</h1>

              <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <div className="col-sm-12">
                    <p><b>Note:</b> Paste showdown importable <b>without nicknames</b> below.</p>
                    <p>{this.state.validText}</p>
                    {dropdown} <br/>
                    <textarea
                        value={this.state.importableText}
                        name="rawInput" rows={20} cols={60}
                        onChange={this.updateImportableText}/>

                        <input className="form-submit-btn" type="submit" value="Submit" />
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default RosterValidation;