import React from "react";
// import configData from "../config.json";
import "../component_css/TeamAnalyzer.css"

import { parseSeasonFromPath, checkEmptyObject, capitalizeFirstLetter,
  computeWeaknessModifier, getRosterInfo, getPokemonIDDetailsInfo,
  getDraftOrderRosterInfo, generateTypes } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();
  

class STABAnalyzer extends React.Component {
  constructor() {
    super()
    this.state = {
        attackPlayer: null,
        defendPlayer: null,
        worstCaseLogic: false,
        coverageTypes: {}
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname);
    this.pokemonData = {};
    this.rosterData = {};
    this.rosterOrder = {};

    this.setAttackPlayer = this.setAttackPlayer.bind(this);
    this.setDefendPlayer = this.setDefendPlayer.bind(this)
    this.generatePlayerDropdown = this.generatePlayerDropdown.bind(this);
    this.generateTeamAnalysisTable = this.generateTeamAnalysisTable.bind(this);
    this.seasonConfig = configData.LEAGUE_CONFIG[this.state.seasonNum];
  }

  componentDidMount() {
    getPokemonIDDetailsInfo(this.seasonNum).then(
      results => {this.pokemonData = results;}
    ).then(
      () => {return getRosterInfo(this.seasonNum)}
    ).then(
      results => {
        this.rosterData = results;
      }
    ).then(
      () => {
        return getDraftOrderRosterInfo(this.seasonNum, "")
      }
    ).then(
      results => {this.rosterOrder = results;}
    ).finally(
      () => {this.setState({
        attackPlayer: Object.keys(this.rosterData)[0],
        defendPlayer: Object.keys(this.rosterData)[1]
      })}
    )
  }

  updateCoverageType(pokeID, newType) {
    const cvgTypes = this.state.coverageTypes;
    cvgTypes[pokeID] = newType;
    this.setState({coverateTypes: cvgTypes});
  }

  generateCoverageDropdown(pokeID) {
    const dropdownOpts = [];
    Object.keys(configData.TYPE_IMAGES).forEach(val => {
      dropdownOpts.push(<option key={pokeID + val} value={val}>{capitalizeFirstLetter(val)}</option>)
    })

    return <div className="type-dropdown-div">
      <select
        value={this.state.coverageTypes[pokeID]}
        onChange={(e) => this.updateCoverageType(pokeID, e.target.value)}>
          <option value={undefined}></option>
          {dropdownOpts}
      </select>
    </div>
  }

  setAttackPlayer(newPlayer) {
    this.setState({attackPlayer: newPlayer})
  }
  setDefendPlayer(newPlayer) {
    this.setState({defendPlayer: newPlayer})
  }

  generatePlayerDropdown(label, func, val) {
    const options = []
    Object.keys(this.rosterData).forEach(value => {
        options.push(<option key={value} value={value}>
            {value}
        </option>)
    })
    return <div className="player-dropdown-div">
        <label>{label}:&nbsp;
        <select name={"gameSelector"}
            value={val}
            onChange={(e) => func(e.target.value)}>
            {options}
        </select>
    </label> 
    </div>
  }

  generateTeamAnalysisTable() {
    if (checkEmptyObject(this.rosterData)) {
        return <div>Hello World!</div>
    }
    const attackRoster = this.rosterOrder[this.state.attackPlayer].roster;
    const defendRoster = this.rosterOrder[this.state.defendPlayer].roster;
    const weaknessList = {}
    const resistList = {}
    const neutralList = {}

    attackRoster.forEach(pokeID => {
        const pokeInfo = this.pokemonData.base[pokeID];
        const pokeTypes = [...pokeInfo.types]; // Make a copy
        if (this.state.coverageTypes[pokeID] !== undefined) {
          pokeTypes.push(this.state.coverageTypes[pokeID])
        }
        console.log(pokeID, pokeTypes);

        defendRoster.forEach(pokeID2 => {
          const pokeInfo2 = this.pokemonData.base[pokeID2];
          const pokeTypes2 = pokeInfo2.types;

          const poke2Weakness = [];
          pokeTypes.forEach(type1 => {
            poke2Weakness.push(computeWeaknessModifier(pokeTypes2, type1));
          });

          let maxSTABModifier = Math.min(...poke2Weakness);
          if (this.state.worstCaseLogic === true) {
            maxSTABModifier = Math.max(...poke2Weakness);
          } 

          if (weaknessList[pokeID] === undefined) {
            weaknessList[pokeID] = []
            resistList[pokeID] = []
            neutralList[pokeID] = []
        }

          if (maxSTABModifier > 1) {
            weaknessList[pokeID].push(pokeInfo2.dex_sprite_url)
          } else if (maxSTABModifier < 1) {
            resistList[pokeID].push(pokeInfo2.dex_sprite_url)
          } else {
            neutralList[pokeID].push(pokeInfo2.dex_sprite_url)
          }
        })
    })

    const tableRows = []
    attackRoster.forEach(pokeID => {
      // Weakness List
      const weaknessPokeIcons = [];
      weaknessList[pokeID].forEach(iconSRC => {
          weaknessPokeIcons.push(
              <img src={iconSRC} alt="doot" key={iconSRC} />
          )
      });
      if (weaknessPokeIcons.length === 0) {weaknessPokeIcons.push("---");}

      // Resistance List
      const resistPokeIcons = []
      resistList[pokeID].forEach(iconSRC => {
          resistPokeIcons.push(
              <img src={iconSRC} alt="doot" key={iconSRC} />
          )
      })
      if (resistPokeIcons.length === 0) {resistPokeIcons.push("---");}

      // Neutral List
      const neutralPokeIcons = []
      neutralList[pokeID].forEach(iconSRC => {
          neutralPokeIcons.push(
              <img src={iconSRC} alt="doot" key={iconSRC} />
          )
      })
      if (neutralPokeIcons.length === 0) {neutralPokeIcons.push("---");}
    
    
      const pokeInfo = this.pokemonData.base[pokeID];
      const attackerSprite = <img src={pokeInfo.dex_sprite_url} alt="doot" key={pokeID} />
      tableRows.push(
          <tr>
              <td> {attackerSprite} <br/> 
                {generateTypes(pokeInfo)}
              </td>
              <td>{this.generateCoverageDropdown(pokeID)} </td>
              <td>{weaknessList[pokeID].length}</td>
              <td>{weaknessPokeIcons}</td>
              <td>{neutralList[pokeID].length}</td>
              <td>{neutralPokeIcons}</td>
              <td>{resistList[pokeID].length}</td>
              <td>{resistPokeIcons}</td>
          </tr>
      )

    })

    return <div>
        <h2>Offensive STAB Coverage Analysis Table</h2>
        <table className="league-table table-responsive table-striped">
        <thead>
        <tr>
            <th>Attacker</th> <th>Coverage</th> <th># Weak</th><th>Weak</th> <th># Neutral</th><th>Neutral</th> <th># Resists</th><th>Resist</th>
        </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </table>
    </div>

  }

  generateLogicToggleButton() {
    return <div className="player-dropdown-div">
    <button className="btn btn-dark" onClick={(e) => {this.setState({worstCaseLogic: !this.state.worstCaseLogic})}}>
      Prioritize {this.state.worstCaseLogic ? "Weaknesses" : "Resistances"}
    </button>
    </div>
  }

  render() {
    return (
      <div className="about">
        <div className="container-fluid h-100">
          <div className="row align-items-center my-5 ">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <h1 className="font-weight-light">Season #{ this.seasonNum } STAB Coverage Analyzer</h1>
              <p><b>Note:</b> Abilities are not taken into account</p>
              {this.generatePlayerDropdown("Attack Roster", this.setAttackPlayer, this.state.attackPlayer)} &nbsp;
              {this.generatePlayerDropdown("Defend Roster", this.setDefendPlayer, this.state.defendPlayer)} &nbsp;
              {this.generateLogicToggleButton()}
              <div className="analyzer-table-div">
                  <div className="table-container">
                  {this.generateTeamAnalysisTable()}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default STABAnalyzer;