// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.league-table {
    width: 100%;
    text-align: center;
    display: table;
}

.league-table td, .league-table th {
    padding-left: 15px;
    padding-right: 15px;
    display: table-cell;
}

.league-table img {
    margin-left: auto;
    margin-right: auto;
}

.league-table-legend {
    width: 80%;
    display:table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.table-container {
    overflow-x: scroll;
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/component_css/LeagueTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".league-table {\n    width: 100%;\n    text-align: center;\n    display: table;\n}\n\n.league-table td, .league-table th {\n    padding-left: 15px;\n    padding-right: 15px;\n    display: table-cell;\n}\n\n.league-table img {\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.league-table-legend {\n    width: 80%;\n    display:table;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 15px;\n}\n\n.table-container {\n    overflow-x: scroll;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
