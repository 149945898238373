import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { parseSeasonFromPath } from './helpers';
import '../component_css/NavBar.css'
// import configData from "../config.json";
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

function GenerateNavigationLinks(seasonNum, pathname, mobile, setMobileNavActive) {
  let output = []

  const forceUpdate = useForceUpdate();

  const tabs = {
    "Main": "/", 
    "Rules": "/rules", 
    "Roster": {
      "Roster": "/roster", 
      "Pokémon Lookup": "/reverselookup",
      "Team Validator": "/teamvalidation",
    },
    "Games": {
      "Game Data": "/gamedata",
      "Game Details": "/gamedetails"
    },
    "League Table": "/leaguetable",
    "Analysis": {
      "Team Analyzer": "/teamanalyzer",
      "STAB Analyzer": "/stabanalyzer",
      "Speed Tiers": "/speedtiers",
      "Usage Stats": "/usagestats",
      "Pairwise Usage": "/pairwiseusage",
      "Battle Stats": "/battlestats"
    },
    "Records": {
      "Draft": "/draft", 
      "Trade/FA Log": "/tradelog", 
      "Free Agents": "/freeagents",
    },
    "Blog": configData["BLOG_URL"]
  }
  
  const [tabClasses, setTabClasses] = useState(new Array(Object.keys(tabs).length).fill("sublist"));
  const [tabClassesMobile, setTabClassesMobile] = useState(new Array(Object.keys(tabs).length).fill(""));

  const clickNested = idx => {
    return () => {
      let current = tabClasses;
      current[idx] === "sublist" ? current[idx] = "sublist active" : current[idx] = "sublist";
      setTabClasses(current);
      forceUpdate();
    }
    
    // sublistClass == "sublist" ? setSublistClass("sublist active") : setSublistClass("sublist");
  }
  
  const clickNestedMobile = idx => {
    return () => {
      let current = tabClassesMobile;
      current[idx] === "" ? current[idx] = "active" : current[idx] = "";
      setTabClassesMobile(current);
      forceUpdate();
    }
  }

  
  for (var i=0; i < Object.keys(tabs).length; i++) {
    const name = Object.keys(tabs)[i];
    const info = Object.values(tabs)[i]
    if (typeof info == 'string') {

      var navbarLink = "doot"
      if (!info.startsWith("/")) {
        navbarLink = <a id="mobile-nav" className="nav-link" href={info}> {name} <span className="sr-only">(current)</span> </a>
      } else {
        navbarLink = <Link id="mobile-nav" className="nav-link" to={"/" + seasonNum + info} >
          {name}
          <span className="sr-only">(current)</span>
        </Link>
      }

      output.push(
        <li id="mobile-nav" className={`nav-item  ${pathname === "/" + seasonNum + "/" + name.toLowerCase().split(" ").join("") ? "active" : ""}`} key={seasonNum + info} onClick={() => mobile ? setMobileNavActive() : ""}>
          {navbarLink}
        </li>
      )
    } else if (typeof info === "object") {
      if (mobile) {
        output.push(
          <li id="mobile-nav" onClick={clickNestedMobile(i)} className={`nav-item  ${pathname === "/" + seasonNum + "/" + name.toLowerCase().split(" ").join("") ? "active" : ""}`} key={seasonNum + i} >
            {name + " ▾"}
          </li>
        )
        for (let j = 0; j < Object.keys(info).length; j++) {
          output.push(
            <li id="mobile-nav" className={`nested nav-item ${tabClassesMobile[i]} ${pathname === "/" + seasonNum + "/" + name.toLowerCase().split(" ").join("") ? "active" : ""}`} key={seasonNum + Object.values(info)[j]} onClick={() => mobile ? setMobileNavActive() : ""}>
              <Link id="mobile-nav" className="nav-link" to={"/" + seasonNum + Object.values(info)[j]}>
                {Object.keys(info)[j]}
                <span className="sr-only">(current)</span>
              </Link>
            </li>
          )
        }
      } else {
        let nested = [];
        for (let j = 0; j < Object.keys(info).length; j++) {
          nested.push(<li className="nested-li" onClick={() => mobile ? setMobileNavActive() : ""}>
            <Link id="mobile-nav" className="nav-link" to={"/" + seasonNum + Object.values(info)[j]}>
              {Object.keys(info)[j]}
              <span className="sr-only">(current)</span>
            </Link>
          </li>)
        }
        console.log(tabClasses[i])
        output.push(
          <li id="mobile-nav" 
            onClick={clickNested(i)} 
            className={`nav-item nest ${pathname === "/" + seasonNum + "/" + name.toLowerCase().split(" ").join("") ? "active" : ""}`} 
            key={seasonNum + i} 
            // onClick={() => mobile ? setMobileNavActive() : ""}
          >
            {name + " ▾"}
            <ul className={tabClasses[i]}>
              {nested}
            </ul>
          </li>
        )
      }
      
    }

    
  }
  return output
}

function Navigation(props) {
  const seasonNum = parseSeasonFromPath(props.location.pathname);
  const [mobileNavActive, setMobileNavActive] = useState(false)

  useEffect(() => {
    const closeNavEvent = document.addEventListener("click", (e) => {
      if (e.target.id !== "mobile-nav" && e.target.classList[0] !== "navbar") {
        setMobileNavActive(false)
      }
    })

    return function cleanup() {
      document.removeEventListener("click", closeNavEvent)
    }
  })
  
  let mobileClass = mobileNavActive ? "navbar-nav-mobile active" : "navbar-nav-mobile";

  let seasonTitle = "QPL Season #".concat(seasonNum)
  if (configData.LEAGUE_CONFIG[seasonNum].MAIN_HEADER !== undefined) {
    seasonTitle = configData.LEAGUE_CONFIG[seasonNum].MAIN_HEADER
  }
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="navbar-container">
          <Link className="navbar-brand" to={"/" + seasonNum + "/"}>
            {seasonTitle}
          </Link>

          <div>
            <ul className="navbar-nav ml-auto">
              {GenerateNavigationLinks(seasonNum, props.location.pathname, false, setMobileNavActive)}
            </ul>
            <div id="mobile-nav" className="nav-menu" onClick={() => setMobileNavActive(!mobileNavActive)}>
              &#9776;
            </div>
          </div>
        </div>
      </nav>
      <ul className={mobileClass} id="mobile-nav">
        {GenerateNavigationLinks(seasonNum, props.location.pathname, true, setMobileNavActive)}
      </ul>
    </div>
  );
}

export default withRouter(Navigation);