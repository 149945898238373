import React from "react";
import '../component_css/LeagueTable.css';

// import configData from "../config.json";

import { parseSeasonFromPath, buildLegend, getPlayerAccounts, buildAccountString } from './helpers';
import {constructLeagueConfigs} from './helpers';
const configData = constructLeagueConfigs();


class LeagueTable extends React.Component {
  constructor() {
    super()
    this.state = {
      data: [],
      seasonNum: parseSeasonFromPath(window.location.pathname)
    }
    this.seasonNum = parseSeasonFromPath(window.location.pathname)
    this.seasonConfig = configData.LEAGUE_CONFIG[this.seasonNum]
    this.playerAccounts = {}
  }

  getTableData(seasonNum) {
    const api_endpoint = configData.SERVER_URL + seasonNum + "/leaguetable"
    return fetch(api_endpoint)
    .then(res => res.json(),
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {alert(error);})
    .then(
      results => {
        this.setState({
          data: results,
          "seasonNum": seasonNum
        });
      }
    )
    .catch(error => console.warn(error));
  }

  buildTable(seasonNum) {
    const tableRows = []
    this.state.data.forEach(datum => {
      const playerConf = datum["player_conference"];
      // In case style not defined
      let style = {};
      if (seasonNum !== undefined) {
        const confStyles = this.seasonConfig["LEAGUE_TABLE"]["CONFERENCE"];
        if (playerConf in confStyles) {
          style = confStyles[playerConf];
        }
      }

      const activeStyle = {}
      if (datum.active === 0) {
        activeStyle["fontStyle"] = "italic"
      }

      let currentStreak = "";
      const streakVal = Math.abs(datum.current_streak)
      if (datum.current_streak < 0) {
        currentStreak = streakVal + " Loss" + (streakVal === 1 ? "" : "es")
      } else if (datum.current_streak > 0) {
        currentStreak = streakVal + " Win" + (streakVal === 1 ? "" : "s")
      } else {
        currentStreak = "--"
      }

      const tooltip = buildAccountString(this.playerAccounts, datum.player_name)

      // Account for Activity Wins/Activity Losses
      let winString = datum.total_wins;
      if (datum.activity_wins > 0) {
        winString = datum.total_wins + " (" + datum.activity_wins + ")";
      }

      // Account for Activity Wins/Activity Losses
      let lossString = datum.total_losses;
      if (datum.activity_losses > 0) {
        lossString = datum.total_losses + " (" + datum.activity_losses + ")";
      }

      tableRows.push(
        <tr key={datum.player_name} style={activeStyle}>
          <td>{datum.active===0 ? "--" : datum.position}</td>
          <td style={style} title={tooltip}>{datum.player_name}</td>
          <td>{winString}</td>
          <td>{lossString}</td>
          <td>{currentStreak}</td>
          <td>{datum.total_diff}</td>
          <td>{datum.avg_diff}</td>
          <td>{datum.games_left}</td>
          <td>{datum.games_behind}</td>
        </tr>
      )
    })
  
    const output = <table className="league-table table-responsive table-striped">
      <thead>
        <tr>
          <th title="Rank is determined based on Wins, W/L Ratio, and Avg. Differential in that order">Rank*</th>
          <th>Name</th>
          <th>Wins</th>
          <th>Losses</th>
          <th title="Computed based on the date of games (not the round)">Current Streak*</th>
          <th>Differential</th>
          <th>Avg. Diff.</th>
          <th>Games Left</th>
          <th>Games Behind</th>
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  
    return output
  }

  componentDidMount() {
    getPlayerAccounts(this.state.seasonNum).then(
      results => {this.playerAccounts = results;}
    ).then(
      this.getTableData(this.state.seasonNum)
    )
  }

  render() {

    const seasonNum = parseSeasonFromPath(window.location.pathname);
    let legend = ""
    try {
      legend = buildLegend(seasonNum);
    } catch(err) {
      console.log(err);
    }
    
    const table = this.buildTable(seasonNum);
    return ( 
      <div className="about">
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <h1 className="font-weight-light">League Table</h1>
              <p> Season #{ seasonNum } League Table </p>
              {legend}
              <div className="table-container">
                {table}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeagueTable;