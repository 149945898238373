import React from "react";
import '../component_css/Roster.css';

import BaseRosterDraftPage from "./BaseRosterDraftPage";
import {prepareGroupData, getRosterInfo, getDraftOrderRosterInfo, buildAccountString} from './helpers';

class Roster extends BaseRosterDraftPage {
  constructor() {
    super()
    this.pageData = {
        keysOrdered: new Set(),
        data: {},
      }
    this.state.landingPageText = "roster";

    this.triggerDraftOrder = this.triggerDraftOrder.bind(this);
  }

  buildTable(tableData) {
    const tableRows = [];
    const tableHeader = [];

    const playerAccounts = this.playerAccounts;
    for (let key in tableData) {
      tableHeader.push(<th title={buildAccountString(playerAccounts, key)}>{key}</th>);
    }

    // Sort pokemon by speed
    if (this.state.sortKey === "speed") {
      const activePokemon = this.state.activePokemon;
      const pokemonInfo = this.pokemonInfo;
      Object.keys(tableData).forEach((playerName) => {
        // Active Pokemon is stored as  <playerName>|<pokemonID> so work with that format
        const playerActivePokemon = activePokemon.filter(x => x.startsWith(playerName));

        tableData[playerName].sort(function(id1, id2) {
          const id1Mod = playerName.concat("|", id1)
          let spe1 = pokemonInfo["base"][id1]["stats"]["spe"];
          if (playerActivePokemon.indexOf(id1Mod) !== -1) {
            spe1 = pokemonInfo["transf"][id1]["stats"]["spe"];
          } 

          const id2Mod = playerName.concat("|", id2)
          // console.log(id2, playerActivePokemon.indexOf(id2Mod))
          let spe2 = pokemonInfo["base"][id2]["stats"]["spe"];
          if (playerActivePokemon.indexOf(id2Mod) !== -1) {
            spe2 = pokemonInfo["transf"][id2]["stats"]["spe"];
          }

          return spe2 - spe1;
        })
      })
    }

    let index = 0;
    while (true) {
      let temp_row = [];
      let keep_going = false;
      // Construct the nth row of the table; empty cell if no pokemon
      for (let [key, value] of Object.entries(tableData)) {
        const roster = value;
        if (index < roster.length) {
          const pokemonKey = "".concat(key).concat("|").concat(roster[index]);
          temp_row.push(<td>
            {this.generatePokemonIconCell(pokemonKey, roster[index])}
          </td>);

          keep_going = true;
        } else {
          temp_row.push(<td></td>)
        }
      }

      // Unless we found a totally empty row, keep going
      if (keep_going === true) {
        tableRows.push(<tr>{temp_row}</tr>);
      } else {
        break;
      }
      index += 1;
    }

    const output = <table className="table-responsive table-striped roster-table">
      <thead>
        <tr>
          {tableHeader}
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  
    return output
  }

  generateTriggerButtons() {
    const parentTrigger = super.generateTriggerButtons();
    let buttonText = null;
    if (this.state.sortKey === "draft") {
      buttonText = "Draft Order";
    } else if (this.state.sortKey === "speed") {
      buttonText = "Speed Order";
    } else {
      buttonText = "Alphabetical Order";
    }
    return <>
      {parentTrigger}
      <button className="triggerButton btn btn-dark"
        onClick={this.triggerDraftOrder}>
          {buttonText}
      </button>
    </>
  }

  triggerDraftOrder() {
    // Hacky but I need to update the state before I issue the request
    const sortKeyOrder =["default", "speed", "draft"]
    const newSortKeyIdx = (sortKeyOrder.indexOf(this.state.sortKey) + 1) % 3;
    this.state.sortKey = sortKeyOrder[newSortKeyIdx];
    console.log(newSortKeyIdx, this.state.sortKey)

    if (this.state.sortKey !== "speed") {
      this.getTableData().then(
        results => {
          this.pageData = results;
        }
      ).then(
        () => {
          this.setState({})
        }
      )
    } else {
      // Need to update state still
      this.setState({})
    }
  }

  getTableData() {
    if (this.state.sortKey === "draft") {
      return getDraftOrderRosterInfo(this.seasonNum, window.location.search).then(
        results => {
          return prepareGroupData(results, "conf_division")
        }
      )
    } else {
      return getRosterInfo(this.seasonNum, window.location.search).then(
        results => {
          return prepareGroupData(results, "conf_division")
        }
      )
    }
  }

  generateMainBodyTable() {
    const tables = []
      this.pageData.keysOrdered.forEach((key) => {
        const colSize = 10 / this.pageData.keysOrdered.length;
        tables.push(
          <div className={"col-md-" + colSize + " roster-division-column h-100" + (this.state.australiaView ? " australia-view" : "")}>
            <h2 className="font-weight-light">{this.seasonConfig.LEAGUE_TABLE.CONFERENCE[key].displayName} Roster</h2>
            {this.buildTable(this.pageData.data[key])}
          </div>
        );
      });

      return <div className={"row align-items-center h-100"}>
        <div className="col-md-1"></div>
        {tables}
      </div>
  }

}

export default Roster;
